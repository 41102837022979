import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { memo, useCallback } from "react";

import InputBase, { type InputBaseProps } from "../InputBase";

export type InputWithMaskProps = InputBaseProps & {
  /** Параметры маски для инпута. */
  options?: MaskitoOptions | null;
};

const InputWithMask: React.FC<InputWithMaskProps> = ({
  options,
  disabled,
  onChange,
  ...props
}: InputWithMaskProps) => {
  const maskedInputRef = useMaskito({ options });

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled || !onChange) {
        return;
      }

      onChange(e.target.value, e);
    },
    [disabled, onChange],
  );

  return <InputBase ref={maskedInputRef} onInput={handleInput} {...props} />;
};

export default memo(InputWithMask);
