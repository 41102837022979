import clsx from "clsx";
import { memo, useState } from "react";

import Button from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import type { TabItem } from "./types";

import * as s from "./Tabs.module.scss";

export type TabsProps<K extends string = string> = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Список вкладок. */
  tabs: TabItem<K>[];
  /** Содержимое вкладок. */
  children: (props: { selectedTabKey: K }) => React.ReactNode;
};

function Tabs<K extends string = string>({
  className,
  tabs,
  children,
}: TabsProps<K>) {
  const [selectedTab, setSelectedTab] = useState<TabItem<K>>(tabs[0]!);

  return (
    <div className={clsx(s["tabs"], className)}>
      <nav className={s["tabs__nav"]}>
        <div className={s["tabs__nav-wrapper"]}>
          {tabs.map((item) => (
            <Button
              key={item.key}
              className={clsx(
                s["tabs__button"],
                item === selectedTab && s["tabs__button_active"],
              )}
              onClick={() => setSelectedTab(item)}
            >
              <Typography
                Component="span"
                typographyType={TypographyType.BODY_LG}
                typographyWeight={TypographyWeight._700}
              >
                {item.label}
              </Typography>
            </Button>
          ))}
        </div>
      </nav>

      <div className={s["tabs__content"]}>
        {children({ selectedTabKey: selectedTab.key })}
      </div>
    </div>
  );
}

export default memo(Tabs);
