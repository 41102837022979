import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { TabKey } from "@repo/data/config";
import GlobalAuthStore from "@repo/data/store/single/GlobalAuthStore";
import GlobalInterfaceRuleStore from "@repo/data/store/single/GlobalInterfaceRuleStore";
import GlobalTabNodeStore from "@repo/data/store/single/GlobalTabNodeStore";
import GlobalUserStore, {
  normalizeUser,
} from "@repo/data/store/single/GlobalUserStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import Header from "@app/pages/auth/Header";
import Layout from "@app/pages/auth/Layout";
import MenuMobile from "@app/pages/auth/MenuMobile";
import Sidebar from "@app/pages/auth/Sidebar";

const promiseLoadUserCurrent = (async () => {
  const { data, isError } = await apiWithChecks(ApiPath.USER_CURRENT, "GET");

  if (data && !isError) {
    GlobalUserStore.getInstance(normalizeUser(data));
    GlobalAuthStore.getInstance().login();
  }
})();

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context, location }) => {
    await promiseLoadUserCurrent;

    if (!context.isAuth) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },

  loader: async () => {
    const ruleStore = GlobalInterfaceRuleStore.getInstance();
    const tabStore = GlobalTabNodeStore.getInstance();

    await tabStore.fetchTabNode();

    await Promise.all([
      ruleStore.fetchRules(),
      tabStore.useTab(TabKey.MENU)?.fetchTree(),
    ]);
  },

  shouldReload: false,

  component: () => (
    <Layout
      headerContent={<Header />}
      menuMobile={<MenuMobile />}
      sidebarContent={<Sidebar />}
    >
      <Outlet />
    </Layout>
  ),

  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});
