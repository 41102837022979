/**
 * Функция для склонения слов в зависимости от числа
 *
 * @param n - число
 * @param one - слово при числе 1
 * @param two - слово при числе 2
 * @param five - слово при числе 5
 *
 * @returns слово с правильным склонением
 */
export const pluralize = (
  n: number,
  one: string,
  two: string,
  five: string,
) => {
  if (n === 0) {
    return five;
  }

  let number = n;
  number = Math.abs(number);
  number %= 100;

  if (number >= 5 && number <= 20) {
    return five;
  }

  number %= 10;

  if (number === 1) {
    return one;
  }

  if (number >= 2 && number <= 4) {
    return two;
  }

  return five;
};

/**
 * Функция для склонения слов в зависимости от числа и добавления числа в строку
 *
 * @param n - число
 * @param one - слово при числе 1
 * @param two - слово при числе 2
 * @param five - слово при числе 5
 *
 * @returns строка с числом и правильным склонением
 */
export const pluralizeTemplate = (
  n: number,
  one: string,
  two: string,
  five: string,
): string => `${n} ${pluralize(n, one, two, five)}`;
