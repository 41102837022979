import { makeObservable, observable, runInAction } from "mobx";

import { apiOrgStructure } from "@repo/api/apiOrgStructure";
import { ApiPath } from "@repo/api/fetchApi";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { OrgStructureFlat } from "@repo/types/orgStructure";

import { normalizeOrgStructure } from "./normalize";

type PrivateFields = "_orgStructure";

class OrgStructureStore {
  private readonly _meta = new MetaStore();

  private _orgStructure: OrgStructureFlat = [];

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _orgStructure: observable.ref,
    });

    this._fetch();
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get orgStructureFlat(): OrgStructureFlat {
    return this._orgStructure;
  }

  async _fetch(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiOrgStructure(
      ApiPath.ORG_STRUCTURE_LIST,
      "GET",
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._orgStructure = normalizeOrgStructure(data);
      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default OrgStructureStore;
