import { Link, type LinkProps } from "@tanstack/react-router";
import { memo, type PropsWithChildren, useMemo } from "react";

import type { EmployeeCard } from "@repo/types/employee";

export type CardLinkProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Информация о сотруднике. */
  employee: EmployeeCard;
};

const CardLink: React.FC<PropsWithChildren<CardLinkProps>> = ({
  className,
  employee,
  children,
}: PropsWithChildren<CardLinkProps>) => {
  const linkProps = useMemo<LinkProps>(() => {
    if (employee.employeeNumber) {
      return {
        to: "/employee/$employeeId",
        params: {
          employeeId: employee.employeeNumber,
        },
      };
    }

    return {
      to: "/user/$userId",
      params: {
        userId: employee.userId,
      },
    };
  }, [employee]);

  return (
    <Link className={className} {...linkProps}>
      {children}
    </Link>
  );
};

export default memo(CardLink);
