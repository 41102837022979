import { computed, makeObservable, observable } from "mobx";

import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";

import type { FieldModel, FieldModelGuard } from "../types";

import type { IFormModel } from "./types";

/** Абстрактная модель формы. */
abstract class AFormModel implements IFormModel {
  protected readonly _meta = new MetaStore();

  protected _fields: Map<string, FieldModel>;

  constructor(fields: FieldModel[] = []) {
    this._fields = fields.reduce((acc, field) => {
      acc.set(field.name, field);

      return acc;
    }, new Map());

    makeObservable<this, "_fields">(this, {
      _fields: observable,

      fields: computed,
      hasFieldsError: computed,
    });

    this.submit = this.submit.bind(this);
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get fields(): FieldModel[] {
    return Array.from(this._fields.values());
  }

  get hasFieldsError(): boolean {
    return this.fields.some((field) => field.hasError);
  }

  getField<T extends FieldModel = FieldModel>(
    name: string,
    isModelCorrect: FieldModelGuard<T>,
  ): T | null {
    const field = this._fields.get(name);

    if (!field || !isModelCorrect(field)) {
      return null;
    }

    return field;
  }

  clear(): void {
    this.fields.forEach((field) => field.clear());
  }

  reset(): void {
    this.fields.forEach((field) => field.reset());
  }

  abstract submit(): Promise<void>;

  destroy(): void {
    this._meta.destroy();

    this.fields.forEach((field) => field.destroy());
  }
}

export default AFormModel;
