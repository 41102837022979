import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const StructureIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="20" viewBox="0 0 20 20" width="20">
      <path
        d="M12.3108 9.28724h3.6405c.3218 0 .6305-.12785.858-.35542.2276-.22758.3555-.53624.3555-.85808V4.43323c0-.32184-.1279-.6305-.3555-.85808-.2275-.22757-.5362-.35542-.858-.35542h-3.6405c-.3219 0-.6305.12785-.8581.35542-.2276.22758-.3555.53624-.3555.85808v1.2135H9.88374c-.48276 0-.94575.19178-1.28711.53314-.34136.34137-.53314.80436-.53314 1.28712v2.427H5.63648v-.60675c0-.32184-.12785-.6305-.35542-.85807-.22758-.22758-.53624-.35543-.85808-.35543H1.99597c-.32184 0-.6305.12785-.85807.35543-.227578.22757-.355429.53623-.355429.85807v2.42696c0 .3219.127851.6305.355429.8581.22757.2276.53623.3555.85807.3555h2.42701c.32184 0 .6305-.1279.85808-.3555.22757-.2276.35542-.5362.35542-.8581v-.6067h2.42701v2.427c0 .4828.19178.9458.53314 1.2871.34136.3414.80435.5332 1.28711.5332h1.21346v1.2135c0 .3218.1279.6305.3555.858.2276.2276.5362.3555.8581.3555h3.6405c.3218 0 .6305-.1279.858-.3555.2276-.2275.3555-.5362.3555-.858v-3.6405c0-.3219-.1279-.6305-.3555-.8581-.2275-.2276-.5362-.3555-.858-.3555h-3.6405c-.3219 0-.6305.1279-.8581.3555-.2276.2276-.3555.5362-.3555.8581v1.2135H9.88374c-.16092 0-.31525-.064-.42903-.1778-.11379-.1137-.17772-.2681-.17772-.429V7.46699c0-.16092.06393-.31525.17772-.42904.11378-.11379.26811-.17771.42903-.17771h1.21346v1.2135c0 .32184.1279.6305.3555.85808.2276.22757.5362.35542.8581.35542ZM4.42298 11.7142H1.99597V9.28724h2.42701v2.42696Zm7.88782 1.2136h3.6405v3.6405h-3.6405v-3.6405Zm0-8.49457h3.6405v3.64051h-3.6405V4.43323Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(StructureIcon);
