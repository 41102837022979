import { runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import type { user } from "@repo/api/types";
import GlobalUserStore, {
  normalizeUser,
  UserStore,
} from "@repo/data/store/single/GlobalUserStore";
import { ContactType, type ContactLinkType } from "@repo/types/contact";
import { MASKITO_MASK_OPTIONS } from "@repo/ui/Input";

import {
  AFormModel,
  FormFieldMask,
  FormFieldSelect,
  FormFieldText,
  isFormModelMask,
  isFormModelSelect,
  isFormModelText,
} from "../../models/FormModel";

import { CONTACT_MAP, ProfileEditField } from "./config";

class ProfileEditStore extends AFormModel {
  private readonly _userStore: UserStore;

  constructor() {
    const userStore = GlobalUserStore.getInstance();

    super([
      new FormFieldText({
        name: ProfileEditField.STATUS,
        value: userStore.data.statusMessage,
      }),
      new FormFieldSelect({
        name: ProfileEditField.TAGS,
        value: userStore.data.labels.map((label) => ({
          key: label.id,
          label: label.title,
          value: label.color,
        })),
      }),
      new FormFieldText({
        name: ProfileEditField.ABOUT,
        value: userStore.data.description,
      }),
      new FormFieldText({
        name: ProfileEditField.VK,
        value: userStore.data.vk ?? "",
      }),
      new FormFieldText({
        name: ProfileEditField.TG,
        value: userStore.data.tg ?? "",
      }),
      new FormFieldMask(MASKITO_MASK_OPTIONS.whatsapp, {
        name: ProfileEditField.WHATSAPP,
        value: userStore.data.whatsapp ?? "",
      }),
    ]);

    this._userStore = userStore;
  }

  private _composeSimpleOptions(): Partial<user.UserCurrentUpdateOptions> {
    const options: Partial<user.UserCurrentUpdateOptions> = {};

    const statusField = this.getField(ProfileEditField.STATUS, isFormModelText);
    const aboutField = this.getField(ProfileEditField.ABOUT, isFormModelText);

    if (statusField && statusField.touched) {
      options.status_message = statusField.value;
    }

    if (aboutField && aboutField.touched) {
      options.description = aboutField.value;
    }

    return options;
  }

  private _composeTagsOptions(): Partial<user.UserCurrentUpdateOptions> {
    const options: Partial<user.UserCurrentUpdateOptions> = {};

    const tagsField = this.getField(ProfileEditField.TAGS, isFormModelSelect);

    const addLabels: number[] = [];
    const removeLabels: number[] = [];

    if (tagsField && tagsField.touched) {
      const userTagIds = this._userStore.data.labels.map(({ id }) => id);
      const selectTagIds = tagsField.value.map(({ key }) => key);

      userTagIds.forEach((id) => {
        if (!selectTagIds.includes(id)) {
          removeLabels.push(id);
        }
      });

      selectTagIds.forEach((id) => {
        if (!userTagIds.includes(id)) {
          addLabels.push(id);
        }
      });
    }

    if (addLabels.length > 0) {
      options.add_labels = addLabels;
    }

    if (removeLabels.length > 0) {
      options.remove_labels = removeLabels;
    }

    return options;
  }

  private _composeContactOptions(): Partial<user.UserCurrentUpdateOptions> {
    const options: Partial<user.UserCurrentUpdateOptions> = {};

    const vkField = this.getField(ProfileEditField.VK, isFormModelText);
    const tgField = this.getField(ProfileEditField.TG, isFormModelText);
    const whatsappField = this.getField(
      ProfileEditField.WHATSAPP,
      isFormModelMask,
    );

    const upsertContacts: ContactLinkType[] = [];
    const removeContacts: ContactType[] = [];

    [vkField, tgField, whatsappField].forEach((field) => {
      if (field && field.touched && field.value !== "") {
        upsertContacts.push({
          type: CONTACT_MAP[field.name],
          link: field.value,
        });
      }

      if (field && field.touched && field.value === "") {
        removeContacts.push(CONTACT_MAP[field.name]);
      }
    });

    if (upsertContacts.length > 0) {
      options.upsert_contacts = upsertContacts;
    }

    if (removeContacts.length > 0) {
      options.remove_contacts = removeContacts;
    }

    return options;
  }

  async submit(): Promise<void> {
    this._meta.setInitialMetaState();

    const requestOptions = {
      ...this._composeSimpleOptions(),
      ...this._composeTagsOptions(),
      ...this._composeContactOptions(),
    };
    const isOptionsEmpty = Object.keys(requestOptions).length === 0;

    if (isOptionsEmpty) {
      return;
    }

    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.USER_CURRENT_UPDATE,
      "POST",
      { data: requestOptions },
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._userStore.setData(normalizeUser(data));
      this._meta.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    super.destroy();
  }
}

export default ProfileEditStore;
