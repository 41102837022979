import clsx from "clsx";
import { memo } from "react";

import type { IconEntity } from "@repo/types/icon";
import { ActionEntity } from "@repo/types/tab";
import Counter, { CounterSize } from "@repo/ui/Counter";
import { IconCompose, IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useTabAction from "@app/hooks/useTabAction";
import useWidth from "@app/hooks/useWidth";

import * as s from "./MenuItem.module.scss";

export type MenuItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Тип иконки для элемента меню. */
  icon: IconEntity;
  /** Заголовок элемента меню. */
  title: string;
  /** Ссылка для перехода при клике на элемент меню. */
  action: ActionEntity;
  /** Счетчик для элемента меню. */
  counter?: {
    /** Основное количество для отображения. */
    count: string;
    /** Дополнительное количество для отображения. */
    allCount?: string;
  };
};

const MenuItem: React.FC<MenuItemProps> = ({
  className,
  icon,
  title,
  action,
  counter,
}: MenuItemProps) => {
  const { isPhone } = useWidth();

  const [Element, actionProps] = useTabAction(action);

  return (
    <Element
      className={clsx(
        s["menu-item"],
        counter && s["menu-item_with-counter"],
        className,
      )}
      {...actionProps}
    >
      <IconCompose
        className={s["menu-item__icon"]}
        data={icon}
        size={IconSize.L}
      />

      <Typography
        Component="p"
        className={s["menu-item__title"]}
        typographyType={TypographyType.BODY_MD}
        typographyWeight={TypographyWeight._500}
      >
        {title}
      </Typography>

      {counter && (
        <Counter
          allCount={counter?.allCount}
          className={s["menu-item__counter"]}
          count={counter.count}
          size={isPhone ? CounterSize.S : CounterSize.L}
        />
      )}
    </Element>
  );
};

export default memo(MenuItem);
