import { OPTION_ALL_EMPLOYEE, type OptionWithKey } from "@repo/types/option";

export const ORG_STRUCTURE_FILTERS: OptionWithKey[] = [
  {
    key: "lyubimaya-kompaniya",
    label: "Любимая компания",
  },
  {
    key: "filial-volga",
    label: "Филиал Волга",
  },
  {
    key: "divizion-samara",
    label: "Дивизион Самара",
  },
  OPTION_ALL_EMPLOYEE,
];
