import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { AvatarChangeStore } from "@repo/data/store/local/AvatarChangeStore";
import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import Button, { ButtonSize, ButtonTheme } from "@repo/ui/Button";
import ImageCropper from "@repo/ui/ImageCropper";
import Modal from "@repo/ui/Modal";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";

import * as s from "./ModalAvatarChange.module.scss";

export type ModalAvatarChangeProps = {
  store: AvatarChangeStore;
};

const ModalAvatarChange: React.FC<ModalAvatarChangeProps> = ({
  store,
}: ModalAvatarChangeProps) => {
  const { isPhone } = useWidth();

  const modalOpenState = useModalsStoreOpenState(ModalsBaseKeys.AVATAR_CHANGE);

  const handleImageChange = useCallback(async () => {
    await store.changeImage();

    modalOpenState.close();
  }, [store, modalOpenState]);

  return (
    <Modal
      className={s["modal-avatar"]}
      isMobile={isPhone}
      isOpened={modalOpenState.opened}
      onClose={modalOpenState.close}
    >
      <Modal.Content className={s["modal-avatar__content"]}>
        {store.image && (
          <ImageCropper
            imageSrc={store.image}
            isLoading={store.meta.isLoading}
            objectFit={"horizontal-cover"}
            onCropComplete={store.setCroppedImage}
          />
        )}

        <Typography
          className={s["modal-avatar__description"]}
          typographyType={
            isPhone ? TypographyType.BODY_SM : TypographyType.BODY_LG
          }
          typographyWeight={TypographyWeight._500}
        >
          Выбранная область будет показываться на Вашей странице. <br />
          Вы можете изменять размер выбранной области, для этого используйте
          колесико мышки.
        </Typography>

        <div className={s["modal-avatar__buttons"]}>
          <Button.Base
            className={s["modal-avatar__button"]}
            disabled={store.meta.isLoading}
            size={isPhone ? ButtonSize.MEDIUM : ButtonSize.ADAPTIVE_MEDIUM}
            onClick={handleImageChange}
          >
            Сохранить
          </Button.Base>

          <Button.Base
            className={s["modal-avatar__button"]}
            disabled={store.meta.isLoading}
            size={isPhone ? ButtonSize.MEDIUM : ButtonSize.ADAPTIVE_MEDIUM}
            theme={ButtonTheme.BORDERED}
            onClick={modalOpenState.close}
          >
            Вернуться назад
          </Button.Base>
        </div>

        {!isPhone && (
          <Modal.Close
            className={s["modal-avatar__close"]}
            onClick={modalOpenState.close}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default observer(ModalAvatarChange);
