import SingleStoreManager from "../SingleStoreManager";

import OrgStructureStore from "./OrgStructureStore";

const GlobalOrgStructureStore = SingleStoreManager.createStore(
  OrgStructureStore,
  {
    destroyed: true,
  },
);

export default GlobalOrgStructureStore;
