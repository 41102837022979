import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { memo, useCallback, useRef, useState } from "react";

import { IconType } from "@repo/types/icon";
import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import { InputSearchSize, InputSearchTheme } from "./config";

import * as s from "./InputSearch.module.scss";

export type InputSearchProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Размер компонента. */
  size?: InputSearchSize;
  /** Тема компонента. */
  theme?: InputSearchTheme;
  /** Показывать кнопку поиска. */
  withSearchButton?: boolean;
  /** Значение поля ввода. */
  value: string;
  /** Callback, вызывается при изменении значения поля ввода. */
  onChange: (value: string) => void;
  /** Callback, вызывается при нажатии кнопки поиска. */
  onSearch?: () => void;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "value" | "onChange"
>;

const InputSearch: React.FC<InputSearchProps> = ({
  className,
  size = InputSearchSize.LARGE,
  theme = InputSearchTheme.MAIN,
  withSearchButton = true,
  value,
  onChange,
  onSearch,
  ...props
}: InputSearchProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    const input = ref.current;

    if (!input || isFocused) {
      return;
    }

    input.focus();

    setIsFocused(true);
  }, [isFocused]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      onChange("");
    },
    [onChange],
  );

  const handleSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (!withSearchButton || !onSearch) {
        return;
      }

      onSearch();
    },
    [withSearchButton, onSearch],
  );

  return (
    <div
      className={clsx(
        s["root"],
        s[`root_size-${size}`],
        s[`root_theme-${theme}`],
        isFocused && s["root_focused"],
        className,
      )}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      <div className={s["root__field"]}>
        <Icon
          className={clsx(
            s["root__icon"],
            Boolean(value) && s["root__icon_filled"],
          )}
          size={IconSize.M}
          type={IconType.SEARCH}
        />

        <input
          ref={ref}
          className={s["root__input"]}
          type="search"
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          {...props}
        />

        <AnimatePresence>
          {value && (
            <motion.div
              animate={{ opacity: 1 }}
              className={s["root__clear"]}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Button.Text
                className={s["root__clear-button"]}
                color={ButtonColor.DEFAULT}
                onClick={handleClear}
              >
                <Icon size={IconSize.M} type={IconType.CLOSE} />
              </Button.Text>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {withSearchButton && theme === InputSearchTheme.MAIN && (
        <Button.Base
          className={s["root__search-button"]}
          color={ButtonColor.SECONDARY}
          onClick={handleSearch}
        >
          <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
        </Button.Base>
      )}

      {withSearchButton && theme === InputSearchTheme.SECONDARY && (
        <AnimatePresence>
          {isFocused && (
            <motion.div
              animate={{ opacity: 1, right: 0 }}
              className={s["root__search"]}
              exit={{ opacity: 0, right: -64 }}
              initial={{ opacity: 0, right: -64 }}
              transition={{ duration: 0.3 }}
            >
              <Button.Base
                className={s["root__search-button"]}
                color={ButtonColor.PRIMARY}
                onClick={handleSearch}
              >
                <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
              </Button.Base>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default memo(InputSearch);
