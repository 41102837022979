import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import type { OrgStructureUnit } from "@repo/types/orgStructure";
import { Card } from "@repo/ui/Card";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";
import { pluralizeTemplate } from "@repo/utils/format";

import * as s from "./OrgStructureLink.module.scss";

export type OrgStructureLinkProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Структура организации. */
  orgStructure: OrgStructureUnit;
};

const OrgStructureLink: React.FC<OrgStructureLinkProps> = ({
  className,
  orgStructure,
}: OrgStructureLinkProps) => {
  return (
    <Link params={{ orgId: orgStructure.depId }} to={"/employees/$orgId"}>
      <Card className={clsx(s["link"], className)}>
        <Typography
          Component="h3"
          typographyType={TypographyType.H3}
          typographyWeight={TypographyWeight._700}
        >
          {orgStructure.depName}
        </Typography>

        <div className={s["link__count"]}>
          <Typography
            Component="p"
            className={s["link__text"]}
            typographyType={TypographyType.H4}
            typographyWeight={TypographyWeight._500}
          >
            {pluralizeTemplate(
              Number(orgStructure.employeeCount),
              "сотрудник",
              "сотрудника",
              "сотрудников",
            )}
          </Typography>

          <div className={s["link__icon"]}>
            <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default memo(OrgStructureLink);
