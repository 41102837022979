import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import * as s from "./MenuMobileButton.module.scss";

export type MenuMobileButtonProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const MenuMobileButton: React.FC<MenuMobileButtonProps> = ({
  className,
}: MenuMobileButtonProps) => {
  return (
    <Button className={clsx(s["root"], className)}>
      <Icon
        className={s["root__icon"]}
        size={IconSize.XL}
        type={IconType.PLUS}
      />
    </Button>
  );
};

export default memo(MenuMobileButton);
