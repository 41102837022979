import clsx from "clsx";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

import * as s from "./Input.module.scss";

export type InputCommonProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Плейсхолдер. */
  placeholder?: string;
  /** Лейбл для поля ввода. */
  label?: string;
  /** Слот для отображения контента после поля ввода. */
  afterSlot?: React.ReactNode;
  /** Если true, поле ввода будет в состоянии ошибки. */
  error?: boolean;
  /** Если true, поле ввода будет заблокировано. */
  disabled?: boolean;
  /** Значение поля ввода. */
  value?: string | number;
  /** Обработчик потери фокуса. */
  onBlur?: () => void;
  /** Обработчик изменения значения. */
  onChange?: (
    value: string | number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

type InputProps = InputCommonProps & {
  /** Вложенный элемент input | textarea. */
  children: (props: {
    className?: string;
    ref: React.RefObject<HTMLElement>;
    id: string;
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
  }) => React.ReactNode;
};

const Input = forwardRef<HTMLElement, InputProps>(
  (
    {
      className,
      placeholder,
      label,
      afterSlot,
      error,
      disabled,
      value,
      onBlur,
      onChange,
      children,
      ...props
    }: InputProps,
    refForward,
  ) => {
    const id = useId();

    const ref = useRef<HTMLElement>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
      if (!refForward) {
        return;
      }

      if (typeof refForward === "function") {
        refForward(ref.current);
        return;
      }

      refForward.current = ref.current;
    }, [refForward]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (disabled || !onChange) {
          return;
        }

        onChange(e.target.value, e);
      },
      [disabled, onChange],
    );

    const handleBlur = useCallback(() => {
      setIsFocused(false);

      onBlur?.();
    }, [onBlur]);

    const handleFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleClick = useCallback<
      React.MouseEventHandler<HTMLDivElement>
    >(() => {
      if (!ref.current || isFocused || disabled) {
        return;
      }

      ref.current.focus();
      setIsFocused(true);
    }, [isFocused, disabled]);

    const isFullView = Boolean(placeholder) || Boolean(value) || isFocused;

    return (
      <div
        className={clsx(
          s["input"],
          afterSlot && s["input_with-slot"],
          error && s["input_error"],
          disabled && s["input_disabled"],
          className,
        )}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
      >
        {Boolean(label) && (
          <label
            className={clsx(
              s["input__label"],
              isFullView && s["input__label_full-view"],
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        {children({
          className: clsx(
            s["input__field"],
            isFullView && s["input__field_full-view"],
          ),
          ref,
          id,
          disabled,
          placeholder,
          value,
          handleChange,
          ...props,
        })}

        {Boolean(afterSlot) && (
          <div className={s["input__slot"]}>{afterSlot}</div>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export default memo(Input);
