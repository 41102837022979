// extracted by mini-css-extract-plugin
var _1 = "tag__kgp1R";
var _2 = "tag_angle-bottom-left__jf3Jw";
var _3 = "tag_angle-bottom-right__DeF6w";
var _4 = "tag_angle-top-left__kK9LW";
var _5 = "tag_angle-top-right__dt6Sg";
var _6 = "tag_color-default__K2XUW";
var _7 = "tag_color-gray__jQpld";
var _8 = "tag_color-primary__lvsSI";
var _9 = "tag_no-pointer__qRFAS";
var _a = "tag_selected___XAVa";
var _b = "tag_size-l__fukh2";
var _c = "tag_size-m__Q4C2p";
var _d = "tag_size-s__y9ddo";
var _e = "tag_size-xs__o5QBC";
var _f = "tag_size-xxs__YsFSv";
export { _1 as "tag", _2 as "tag_angle-bottom-left", _3 as "tag_angle-bottom-right", _4 as "tag_angle-top-left", _5 as "tag_angle-top-right", _6 as "tag_color-default", _7 as "tag_color-gray", _8 as "tag_color-primary", _9 as "tag_no-pointer", _a as "tag_selected", _b as "tag_size-l", _c as "tag_size-m", _d as "tag_size-s", _e as "tag_size-xs", _f as "tag_size-xxs" }
