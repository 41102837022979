export enum AvatarSize {
  /** height: 44px/44px */
  XS = "xs",
  /** height: 36px/60px */
  S = "s",
  /** height: 36px/64px */
  M = "m",
  /** height: 100% */
  UNSET = "unset",
}
