import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./FormFieldset.module.scss";

export type FormFieldsetProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок для группы полей. */
  title?: string;
};

const FormFieldset: React.FC<PropsWithChildren<FormFieldsetProps>> = ({
  className,
  title,
  children,
}: PropsWithChildren<FormFieldsetProps>) => {
  return (
    <fieldset className={clsx(s["form-fieldset"], className)}>
      {title && (
        <Typography
          Component="legend"
          className={s["form-fieldset__legend"]}
          typographyType={TypographyType.H4}
          typographyWeight={TypographyWeight._700}
        >
          {title}
        </Typography>
      )}

      {children}
    </fieldset>
  );
};

export default memo(FormFieldset);
