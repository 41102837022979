import { createContextLocalStore } from "../createContextLocalStore";

import ModalOrgStructureStore from "./ModalOrgStructureStore";

const { Provider, useStore } = createContextLocalStore(ModalOrgStructureStore);

export {
  Provider as ModalOrgStructureStoreProvider,
  useStore as useModalOrgStructureStore,
  ModalOrgStructureStore,
};
