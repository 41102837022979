import type { EmployeeCard } from "@repo/types/employee";

import avatarOne from "./employee-1.png";

type EmployeeDetail = {
  id: string;
  title: string;
  text: string;
};

export const EMPLOYEE_DATA: EmployeeCard = {
  avatar: avatarOne,
  department: "Отдел закупок",
  email: "andreev@hello-hr.ru",
  employeeNumber: "1",
  employeePosition: "Руководитель отдела закупок",
  fullName: "Андреев Сергей Геннадиевич",
  isFired: false,
  isManager: false,
  labels: [
    {
      id: 1,
      title: "Label 1",
      color: "#422f7c",
    },
    {
      id: 2,
      title: "Label 2",
      color: "#daccff",
    },
  ],
  orgStructure: [
    {
      key: "lyubimaya-kompaniya",
      label: "Любимая компания",
    },
    {
      key: "filial-volga",
      label: "Филиал Волга",
    },
    {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
  ],
  orgStructureKey: {
    key: "divizion-samara",
    label: "Дивизион Самара",
  },
  phone: "+7 964 999-99-98",
  subordinates: null,
  userId: 1,
  userPosition: "Руководитель",
};

export const ORGANIZATION_DATA: EmployeeDetail[] = [
  {
    id: "1",
    title: "Стаж",
    text: "1 год и 3 месяца",
  },
  {
    id: "2",
    title: "Юридическое лицо",
    text: "ООО «Любимая компания»",
  },
  {
    id: "3",
    title: "Дата трудоустройства",
    text: "15.08.22",
  },
  {
    id: "4",
    title: "Оргструктура",
    text: "Любимая компания/филиал Волга/Отдел закупок",
  },
  {
    id: "5",
    title: "Должность",
    text: "Менеджер, занятость 100%",
  },
  {
    id: "6",
    title: "Место работы",
    text: "Офис «Океан», г. Самара, ул. Спортивная, д.13/2",
  },
  {
    id: "7",
    title: "Удаленная работа",
    text: "Нет",
  },
  {
    id: "8",
    title: "Форма работы",
    text: "Очная, график 5/2",
  },
];
