import { createContextLocalStore } from "../createContextLocalStore";

import EmployeeStore from "./EmployeeStore";

const { Provider, useStore } = createContextLocalStore(EmployeeStore);

export {
  Provider as EmployeeStoreProvider,
  useStore as useEmployeeStore,
  EmployeeStore,
};
