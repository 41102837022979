import type { ErrorType, Validator } from "@repo/types/form";

import { ErrorMessage } from "./config";

export const composeValidators =
  <V extends string | number>(...validators: Validator<V>[]): Validator<V> =>
  (value: V): ErrorType =>
    validators.reduce<ErrorType>(
      (acc, validator) => [...acc, ...validator(value)],
      [],
    );

export const validateNotEmpty =
  <V extends string | number>(
    errorText: string = ErrorMessage.REQUIRED_FIELD,
  ): Validator<V> =>
  (value?: V | null): ErrorType => {
    return value === undefined || value === null || !String(value).trim().length
      ? [errorText]
      : [];
  };

export const validateLength =
  (length: number, errorText: string): Validator =>
  (value: string): ErrorType => {
    return value.length !== length ? [errorText] : [];
  };
