import { makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import type { ILocalStore } from "@repo/data/store/interface";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { EmployeeType } from "@repo/types/employee";

import { normalizeEmployee } from "./normalize";

type PrivateFields = "_employee";

class EmployeeStore implements ILocalStore {
  private readonly _meta = new MetaStore();

  private _employeeNumber: string;
  private _employee: EmployeeType | null = null;

  constructor(employeeNumber: string) {
    this._employeeNumber = employeeNumber;

    makeObservable<this, PrivateFields>(this, {
      _employee: observable.ref,
    });

    this.fetch();
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get employee(): EmployeeType | null {
    return this._employee;
  }

  async fetch(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.EMPLOYEE_CARD_ITEM,
      "GET",
      { data: { employee_tabnumber: this._employeeNumber } },
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._employee = normalizeEmployee(data);
      this._meta.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    this._meta.destroy();
  }
}

export default EmployeeStore;
