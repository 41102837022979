export enum ModalsBaseKeys {
  /** Модальное окно историй из карусели. */
  STORY = "story",
  /** Модальное окно изменения аватара. */
  AVATAR_CHANGE = "avatar-change",
  /** Модальное окно редактирования профиля. */
  PROFILE_EDIT = "profile-edit",
  /** Модальное окно оргструктуры. */
  ORG_STRUCTURE = "org-structure",
}
