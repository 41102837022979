import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useLocalStore } from "@repo/data/hooks";
import { useAvatarChangeStore } from "@repo/data/store/local/AvatarChangeStore";
import { LabelStore } from "@repo/data/store/local/LabelStore";
import {
  ProfileEditField,
  ProfileEditStore,
} from "@repo/data/store/local/ProfileEditStore";
import { useUser } from "@repo/data/store/single/GlobalUserStore";
import { AvatarEdit } from "@repo/ui/Avatar";
import { ButtonColor, ButtonSize } from "@repo/ui/Button";
import Form from "@repo/ui/Form";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import { ProfileEditTabStage } from "../../config";

import * as s from "./ProfileEditForm.module.scss";

export type ProfileEditFormProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Функция, вызываемая при изменении шага редактирования. */
  onStageChange: (stage: ProfileEditTabStage) => void;
  /** Функция, вызываемая при закрытии модального окна. */
  onModalClose: () => void;
};

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  className,
  onStageChange,
  onModalClose,
}: ProfileEditFormProps) => {
  const user = useUser();

  const avatarStore = useAvatarChangeStore();

  const editStore = useLocalStore(() => new ProfileEditStore());
  const labelStore = useLocalStore(() => new LabelStore());

  const handleAvatarChange = useCallback(
    async (files: FileList) => {
      const [photo] = files;

      if (!photo) {
        return;
      }

      await avatarStore.setImage(photo);

      onStageChange(ProfileEditTabStage.AVATAR);
    },
    [avatarStore, onStageChange],
  );

  const handleFormSubmit = useCallback(() => {
    if (editStore.meta.isLoaded && !editStore.hasFieldsError) {
      onModalClose();
    }
  }, [editStore.meta.isLoaded, editStore.hasFieldsError, onModalClose]);

  return (
    <div className={clsx(s["form-stage"], className)}>
      {labelStore.meta.isLoading ? (
        <Loader isAbsolute size={LoaderSize.S} />
      ) : (
        <>
          <AvatarEdit src={user.avatar} onChange={handleAvatarChange} />

          <Form
            className={s["form-stage__form"]}
            store={editStore}
            onSubmit={handleFormSubmit}
          >
            <Form.Fieldset>
              <Form.Text label="Статус" name={ProfileEditField.STATUS} />

              {labelStore.hasLabels && (
                <Form.Select
                  name={ProfileEditField.TAGS}
                  options={labelStore.labelOptions}
                  placeholder="Теги"
                />
              )}

              <Form.Textarea
                label="Обо мне"
                minRows={5}
                name={ProfileEditField.ABOUT}
              />
            </Form.Fieldset>

            <Form.Fieldset title="Соцсети">
              <Form.Text
                name={ProfileEditField.VK}
                placeholder="VK: vk.com/nickname"
              />

              <Form.Text
                name={ProfileEditField.TG}
                placeholder="Telegram: @nickname"
              />

              <Form.Mask
                name={ProfileEditField.WHATSAPP}
                placeholder="Whatsapp: +7 XXX-XXX-XX-XX"
              />
            </Form.Fieldset>

            <Form.Disclaimer>
              Для изменения прочих данных ФИО, телефон и т.д. обратитесь к
              администратору
            </Form.Disclaimer>

            <div className={s["form-stage__submit"]}>
              <Form.Submit
                color={ButtonColor.SECONDARY}
                size={ButtonSize.LARGE}
              >
                Сохранить
              </Form.Submit>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default observer(ProfileEditForm);
