import type { TabItem } from "@repo/ui/Tabs";

export enum ProfileEditTabKey {
  PERSONAL_INFORMATION = "personal-information",
}

export const profileEditTabs: TabItem<ProfileEditTabKey>[] = [
  {
    key: ProfileEditTabKey.PERSONAL_INFORMATION,
    label: "Личная информация",
  },
];
