import clsx from "clsx";
import { memo, useCallback, useMemo, useState } from "react";

import type { TreeData } from "@repo/types/tree";

import TreeNode from "./TreeNode";
import { findTreePath } from "./utils";

import * as s from "./Tree.module.scss";

export type TreeProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Данные для построения дерева. */
  data: TreeData[];
  /** Мобильная версия. */
  isMobile: boolean;
  /** Выбранный элемент дерева по умолчанию. */
  initSelectedKey?: string;
  /** Функция, вызывается при выборе элемента дерева. */
  onSelect: (node: TreeData, parentNode: TreeData | null) => void;
};

const Tree: React.FC<TreeProps> = ({
  className,
  data,
  isMobile,
  initSelectedKey,
  onSelect,
}: TreeProps) => {
  const [selectedKey, setSelectedKey] = useState<string | null>(
    initSelectedKey || null,
  );

  const initialSelectedPath = useMemo(() => {
    if (!initSelectedKey) {
      return null;
    }

    return findTreePath(data, initSelectedKey);
  }, [data, initSelectedKey]);

  const handleSelect = useCallback(
    (node: TreeData, parentNode: TreeData | null) => {
      setSelectedKey(node.key);
      onSelect(node, parentNode);
    },
    [onSelect],
  );

  return (
    <div className={clsx(s["tree"], className)}>
      {data.map((node) => (
        <TreeNode
          key={node.key}
          data={node}
          indent={0}
          initialSelectedPath={initialSelectedPath}
          isMobile={isMobile}
          selectedKey={selectedKey}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );
};

export default memo(Tree);
