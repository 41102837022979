import type { client } from "@repo/api/types";

import S3ResourceModel from "../../models/S3ResourceModel";

import type { SettingsType } from "./types";

export const normalizeSettings = (
  data: client.ClientItemApi,
): SettingsType => ({
  companyName: data.company_name,
  id: data.id,
  systemName: data.name,
  welcomeImage: data.welcome_image
    ? new S3ResourceModel(data.welcome_image).url
    : null,
});
