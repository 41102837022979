import type { OrgStructureFlat } from "@repo/types/orgStructure";
import type { TreeData } from "@repo/types/tree";

function buildOrgStructureTree(
  data: OrgStructureFlat,
  parentId = "",
): TreeData[] {
  return data
    .filter((unit) => unit.parentDepId === parentId)
    .map((unit) => {
      const children = buildOrgStructureTree(data, unit.depId);

      return {
        parentKey: unit.parentDepId,
        key: unit.depId,
        label: unit.depName,
        counter: unit.employeeCount,
        children: children.length > 0 ? children : null,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default buildOrgStructureTree;
