import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const DotIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <circle cx="12" cy="12" fill="currentColor" r="2" />
    </IconBase>
  );
};

export default memo(DotIcon);
