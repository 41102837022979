import clsx from "clsx";
import { observer } from "mobx-react-lite";

import type { SlideType } from "@repo/types/story";
import Button, { ButtonColor, ButtonSize, ButtonTheme } from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";
import { formatDateDDMMYYYY } from "@repo/utils/format";

import useModalStories from "@app/hooks/useModalStories";
import useTabAction from "@app/hooks/useTabAction";
import useWidth from "@app/hooks/useWidth";

import * as s from "./SlideItem.module.scss";

export type SlideItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Данные слайда. */
  item: SlideType;
};

const SlideItem: React.FC<SlideItemProps> = ({
  className,
  item,
}: SlideItemProps) => {
  const { isPhone } = useWidth();

  const modalStories = useModalStories();
  const [Element, actionProps] = useTabAction(item.action);

  return (
    <div
      className={clsx(s["root"], className)}
      style={{
        backgroundImage: `url(${item.attachment})`,
      }}
    >
      <div className={s["root__content"]}>
        <Typography
          typographyType={
            isPhone ? TypographyType.BODY_MD : TypographyType.BODY_LG
          }
          typographyWeight={TypographyWeight._500}
        >
          {formatDateDDMMYYYY(new Date(item.createdAt))}
        </Typography>

        <Typography
          typographyType={isPhone ? TypographyType.H1 : TypographyType.H3}
          typographyWeight={TypographyWeight._700}
        >
          {item.title}
        </Typography>

        <Typography
          typographyType={
            isPhone ? TypographyType.BODY_MD : TypographyType.BODY_LG
          }
          typographyWeight={TypographyWeight._500}
        >
          {item.text}
        </Typography>

        <Element
          className={s["root__action"]}
          onClick={modalStories.handleClose}
          {...actionProps}
        >
          <Button.Base
            color={ButtonColor.TERTIARY}
            size={ButtonSize.MEDIUM}
            theme={ButtonTheme.BORDERED}
          >
            {item.action.value?.title
              ? item.action.value.title
              : "Узнать больше"}
          </Button.Base>
        </Element>
      </div>
    </div>
  );
};

export default observer(SlideItem);
