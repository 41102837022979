import { memo, useCallback } from "react";

import type { OptionWithKey } from "@repo/types/option";
import { LabelPath } from "@repo/ui/Label";
import { TagSize } from "@repo/ui/Tag";

import CardProfile, { CardDetail, type CardProfileProps } from "../CardProfile";

export type CardEmployeeProps = CardProfileProps & {
  /** Обработчик клика по оргструктуре. */
  onSelect?: (value: OptionWithKey) => void;
};

const CardEmployee: React.FC<CardEmployeeProps> = ({
  className,
  employee,
  withBorder = false,
  isMobile,
  onSelect,
}: CardEmployeeProps) => {
  const handleSelect = useCallback(
    (value: OptionWithKey) => {
      onSelect?.(value);
    },
    [onSelect],
  );

  return (
    <CardProfile
      className={className}
      employee={employee}
      isMobile={isMobile}
      withBorder={withBorder}
    >
      {employee.orgStructure && (
        <CardDetail isMobile={isMobile} title="Оргструктура">
          <LabelPath
            options={employee.orgStructure}
            selected={employee.orgStructureKey}
            separator="/"
            size={TagSize.XS}
            onSelect={handleSelect}
          />
        </CardDetail>
      )}
    </CardProfile>
  );
};

export default memo(CardEmployee);
