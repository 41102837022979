import clsx from "clsx";
import {
  forwardRef,
  memo,
  type PropsWithChildren,
  Ref,
  useCallback,
} from "react";

import type { ButtonColor, ButtonSize, ButtonTheme } from "./config";

import * as s from "./Button.module.scss";

export type ButtonDefaultProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Тип кнопки. */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  /** Если true, кнопка будет в состоянии загрузки. */
  loading?: boolean;
  /** Если true, кнопка будет отключена. */
  disabled?: boolean;
  /** Содержимое, отображаемое перед children. */
  before?: React.ReactNode;
  /** Содержимое, отображаемое после children. */
  after?: React.ReactNode;
  /** Обработчик события клика для кнопки. */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & React.HTMLAttributes<HTMLButtonElement>;

export type ButtonStyleProps = {
  /** Размер кнопки. */
  size?: ButtonSize;
  /** Цвет кнопки. */
  color?: ButtonColor;
  /** Тема кнопки. */
  theme?: ButtonTheme;
};

const ButtonDefault = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonDefaultProps>
>(
  (
    {
      className,
      type = "button",
      loading,
      disabled,
      children,
      before,
      after,
      onClick,
      ...props
    }: ButtonDefaultProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const classNames = clsx(
      s["button"],
      loading && s["button_loading"],
      disabled && s["button_disabled"],
      className,
    );

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled || !onClick) {
          return;
        }

        onClick(e);
      },
      [disabled, onClick],
    );

    return (
      <button
        ref={ref}
        className={classNames}
        disabled={disabled}
        type={type}
        onClick={handleClick}
        {...props}
      >
        {before}
        {children}
        {after}
      </button>
    );
  },
);

ButtonDefault.displayName = "Button";

export default memo(ButtonDefault);
