import { useMergeRefs } from "@floating-ui/react";
import { ReactNode, forwardRef } from "react";

import { usePopoverContext } from "../../context";

type PopoverTriggerProps = {
  children: (
    props: {
      ref: ReturnType<typeof useMergeRefs>;
      isOpened: boolean;
    } & Record<string, unknown>,
  ) => ReactNode;
};

const PopoverTrigger = forwardRef<HTMLElement, PopoverTriggerProps>(
  ({ children, ...props }, propRef) => {
    const context = usePopoverContext();

    const ref = useMergeRefs([context.refs.setReference, propRef]);

    return children({
      ref,
      isOpened: context.open,
      ...context.getReferenceProps(props),
    });
  },
);

PopoverTrigger.displayName = "PopoverTrigger";

export default PopoverTrigger;
