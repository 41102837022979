import { action, computed, makeObservable, observable } from "mobx";

import type { ILocalStore } from "@repo/data/store/interface";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import { OPTION_ALL_EMPLOYEE } from "@repo/types/option";
import type { OptionWithKey } from "@repo/types/option";

import { ORG_STRUCTURE_FILTERS } from "./__mocks__";

type PrivateFields = "_filters" | "_selectedFilter";

class FilterOrgStructureStore implements ILocalStore {
  protected readonly _meta = new MetaStore();

  protected _filters: Map<string, OptionWithKey> = new Map();
  protected _selectedFilter: OptionWithKey | null = null;

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _filters: observable.ref,
      _selectedFilter: observable.ref,

      filters: computed,

      setDefaultFilter: action,
      setSelectedFilter: action,
    });

    this._fetch();
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get filters(): OptionWithKey[] {
    return Array.from(this._filters.values());
  }

  get selectedFilter(): OptionWithKey | null {
    return this._selectedFilter;
  }

  getFilterByKey(key: string): OptionWithKey | null {
    return this._filters.get(key) ?? null;
  }

  setDefaultFilter(): void {
    this._selectedFilter = OPTION_ALL_EMPLOYEE;
  }

  setSelectedFilter(selectedFilter: OptionWithKey | null): void {
    this._selectedFilter = selectedFilter;
  }

  _fetch(): Promise<void> {
    return new Promise(() => {
      this._filters = ORG_STRUCTURE_FILTERS.reduce((acc, item) => {
        acc.set(item.key, item);
        return acc;
      }, new Map());
    });
  }

  destroy(): void {
    this._meta.destroy();
  }
}

export default FilterOrgStructureStore;
