import { format } from "date-fns";

import { MONTHS_GEN } from "./config";

/**
 * Форматирование даты в формате dd.MM.yyyy
 *
 * @param {Date | null} date - дата
 * @returns {string}
 */
export const formatDateDDMMYYYY = (date: Date | null): string =>
  format(date || new Date(), "dd.MM.yyyy");

/**
 * Форматирование даты в формате dd MMMM в родительном падеже
 * @param {Date | null} date - дата
 * @returns {string}
 */
export const formatDateDDMMMM = (date: Date | null): string =>
  date
    ? `${date.getDate()} ${MONTHS_GEN[date.getMonth()]?.toLocaleLowerCase()}`
    : "-";
