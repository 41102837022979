import clsx from "clsx";
import { memo } from "react";

import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import { IconType } from "@repo/types/icon";
import Button, {
  ButtonColor,
  ButtonTheme,
  type ButtonBaseProps,
} from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";

import * as s from "./ButtonOrgStructure.module.scss";

export type ButtonOrgStructureProps = ButtonBaseProps;

const ButtonOrgStructure: React.FC<ButtonOrgStructureProps> = ({
  className,
  ...props
}: ButtonOrgStructureProps) => {
  const { isPhone } = useWidth();

  const modalState = useModalsStoreOpenState(ModalsBaseKeys.ORG_STRUCTURE);

  return (
    <Button.Base
      before={
        <Icon
          className={s["button-structure__icon"]}
          size={IconSize.M}
          type={IconType.STRUCTURE}
        />
      }
      className={clsx(
        s["button-structure"],
        isPhone && s["button-structure_mobile"],
        className,
      )}
      color={ButtonColor.DEFAULT}
      theme={ButtonTheme.BORDERED}
      onClick={modalState.open}
      {...props}
    >
      {!isPhone && "Оргcтруктура"}
    </Button.Base>
  );
};

export default memo(ButtonOrgStructure);
