import AFormField from "../AFormField";
import type { FieldModelOptions } from "../AFormField";

class FormFieldText<V extends string = string> extends AFormField<V> {
  constructor(options: FieldModelOptions<V>) {
    super({ ...options });
  }

  protected _prepareValue(value: V): V {
    return value;
  }

  destroy(): void {
    super.destroy();
  }
}

export default FormFieldText;
