import { IconType } from "@repo/types/icon";

import ArrowDown3dIcon from "./ArrowDown3dIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import BellIcon from "./BellIcon";
import Calendar3dIcon from "./Calendar3dIcon";
import CalendarIcon from "./CalendarIcon";
import CheckPaper3dIcon from "./CheckPaper3dIcon";
import CloseIcon from "./CloseIcon";
import CloseMenuIcon from "./CloseMenuIcon";
import Coins3dIcon from "./Coins3dIcon";
import Doctor3dIcon from "./Doctor3dIcon";
import Document3dIcon from "./Document3dIcon";
import DotIcon from "./DotIcon";
import DotsIcon from "./DotsIcon";
import EditIcon from "./EditIcon";
import ExtendIcon from "./ExtendIcon";
import EyeCloseIcon from "./EyeCloseIcon";
import EyeOpenIcon from "./EyeOpenIcon";
import House3dIcon from "./House3dIcon";
import { type IconBaseProps } from "./IconBase";
import IconDoubleCheck from "./IconDoubleCheck";
import IconHand from "./IconHand";
import Info3dIcon from "./Info3dIcon";
import LockKeyIcon from "./LockKeyIcon";
import Menu3dIcon from "./Menu3dIcon";
import MinusIcon from "./MinusIcon";
import Money3dIcon from "./Money3dIcon";
import MoonIcon from "./MoonIcon";
import News3dIcon from "./News3dIcon";
import People3dIcon from "./People3dIcon";
import Phone3dIcon from "./Phone3dIcon";
import PhotoIcon from "./PhotoIcon";
import PlusIcon from "./PlusIcon";
import Profile3dIcon from "./Profile3dIcon";
import Reports3dIcon from "./Reports3dIcon";
import RoundCheck from "./RoundCheck";
import SearchIcon from "./SearchIcon";
import Settings3dIcon from "./Settings3dIcon";
import StructureIcon from "./StructureIcon";
import SunIcon from "./SunIcon";
import Support3dIcon from "./Support3dIcon";
import Toy3dIcon from "./Toy3dIcon";
import Tree3dIcon from "./Tree3dIcon";

export const ICON_ENTITIES: Readonly<
  Record<IconType, React.ComponentType<IconBaseProps>>
> = {
  [IconType.ARROW_DOWN]: ArrowDownIcon,
  [IconType.ARROW_RIGHT]: ArrowRightIcon,
  [IconType.BELL]: BellIcon,
  [IconType.CALENDAR]: CalendarIcon,
  [IconType.CLOSE]: CloseIcon,
  [IconType.CLOSE_MENU]: CloseMenuIcon,
  [IconType.DOT]: DotIcon,
  [IconType.DOTS]: DotsIcon,
  [IconType.DOUBLE_CHECK]: IconDoubleCheck,
  [IconType.EDIT]: EditIcon,
  [IconType.EXTEND]: ExtendIcon,
  [IconType.EYE_CLOSE]: EyeCloseIcon,
  [IconType.EYE_OPEN]: EyeOpenIcon,
  [IconType.HAND]: IconHand,
  [IconType.LOCK_KEY]: LockKeyIcon,
  [IconType.MINUS]: MinusIcon,
  [IconType.MOON]: MoonIcon,
  [IconType.PHOTO]: PhotoIcon,
  [IconType.PLUS]: PlusIcon,
  [IconType.ROUND_CHECK]: RoundCheck,
  [IconType.SEARCH]: SearchIcon,
  [IconType.STRUCTURE]: StructureIcon,
  [IconType.SUN]: SunIcon,

  /** 3D Icons */

  [IconType.ARROW_DOWN_3D]: ArrowDown3dIcon,
  [IconType.CALENDAR_3D]: Calendar3dIcon,
  [IconType.CHECK_PAPER_3D]: CheckPaper3dIcon,
  [IconType.COINS_3D]: Coins3dIcon,
  [IconType.DOCTOR_3D]: Doctor3dIcon,
  [IconType.DOCUMENT_3D]: Document3dIcon,
  [IconType.INFO_3D]: Info3dIcon,
  [IconType.HOUSE_3D]: House3dIcon,
  [IconType.MENU_3D]: Menu3dIcon,
  [IconType.MONEY_3D]: Money3dIcon,
  [IconType.NEWS_3D]: News3dIcon,
  [IconType.PEOPLE_3D]: People3dIcon,
  [IconType.PHONE_3D]: Phone3dIcon,
  [IconType.PROFILE_3D]: Profile3dIcon,
  [IconType.REPORTS_3D]: Reports3dIcon,
  [IconType.SETTINGS_3D]: Settings3dIcon,
  [IconType.SUPPORT_3D]: Support3dIcon,
  [IconType.TREE_3D]: Tree3dIcon,
  [IconType.TOY_3D]: Toy3dIcon,
};
