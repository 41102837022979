import type { employee } from "@repo/api/types";
import S3ResourceModel from "@repo/data/store/models/S3ResourceModel";
import type { EmployeeType } from "@repo/types/employee";

import { normalizeOrgStructureCrumb } from "../../single/GlobalOrgStructureStore/normalize";

export const normalizeEmployee = (
  rawData: employee.EmployeeApi,
): EmployeeType => {
  const links = rawData.user_contacts.reduce((acc, item) => {
    const key = item.type.toLowerCase();
    acc[key] = item.link;

    return acc;
  }, {});

  return {
    avatar: rawData.avatar ? new S3ResourceModel(rawData.avatar).url : null,
    birthday: rawData.birthday ? new Date(rawData.birthday) : null,
    citizenship: rawData.citizenship,
    citizenshipId: rawData.citizenship_id,
    department: rawData.department,
    depStructure: normalizeOrgStructureCrumb(rawData.dep_structure),
    description: rawData.description,
    email: rawData.email,
    employeeNumber: rawData.employee_tabnumber,
    employmentRate: rawData.employment_rate,
    fullName: rawData.fio,
    gender: rawData.gender,
    hrNumber: rawData.hr_tabnumber,
    isFired: Boolean(rawData.is_fired),
    isPartially: Boolean(rawData.is_parttimer),
    labels: rawData.labels,
    mainEmployeeNumber: rawData.main_employee_tabnumber,
    managerNumber: rawData.manager_tabnumber,
    orgId: rawData.org_id,
    orgName: rawData.org_name,
    persId: rawData.pers_id,
    phone: rawData.phone,
    position: rawData.position,
    positionId: rawData.position_id,
    positionTable: rawData.position_table,
    positionTableId: rawData.position_table_id,
    positionTypical: rawData.position_typical,
    positionTypicalId: rawData.position_typical_id,
    statusMessage: rawData.status_message,
    userContacts: rawData.user_contacts,
    workAddress: rawData.work_address,
    workEndDate: rawData.work_end_date ? new Date(rawData.work_end_date) : null,
    workPhone: rawData.work_phone,
    workPlace: rawData.work_place,
    workStartDate: rawData.work_start_date
      ? new Date(rawData.work_start_date)
      : null,
    ...links,
  };
};
