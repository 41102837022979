import clsx from "clsx";
import { memo } from "react";

import placeholder from "../../../images/placeholder.png";
import { AvatarSize } from "../config";

import * as s from "./AvatarImage.module.scss";

export type AvatarImageProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Ссылка на изображение. */
  src: string | null;
  /** Альтернативный текст для изображения. */
  alt?: string;
  /** Размер аватара. */
  size?: AvatarSize;
  /** Округлённые углы. */
  round?: boolean;
  /** Граница аватара. */
  border?: boolean;
};

const AvatarImage: React.FC<AvatarImageProps> = ({
  className,
  src,
  alt = "Аватар пользователя",
  size = AvatarSize.S,
  round = false,
  border = false,
}: AvatarImageProps) => {
  const classNames = clsx(
    s["avatar"],
    s[`avatar_size-${size}`],
    round && s["avatar_round"],
    border && s["avatar_border"],
    className,
  );

  return (
    <div className={classNames}>
      <img alt={alt} className={s["avatar__image"]} src={src || placeholder} />
    </div>
  );
};

export default memo(AvatarImage);
