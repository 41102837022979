import clsx from "clsx";
import React, { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import { WidgetCardNumberType } from "./types";

import * as s from "./WidgetCardNumber.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Значение и заголовок */
  cardNumber: WidgetCardNumberType;
};

const WidgetCardNumber: React.FC<Props> = ({ className, cardNumber }) => {
  const { isDesktop } = useWidth();

  return (
    <div
      className={clsx(
        className,
        s["root"],
        s[`root_color-${cardNumber.color}`],
      )}
    >
      <Typography
        typographyType={TypographyType.NUMBERS_LG}
        typographyWeight={TypographyWeight._800}
      >
        {cardNumber.value}
      </Typography>
      <Typography
        className={s["root__title"]}
        typographyType={
          isDesktop ? TypographyType.CAPS_SM : TypographyType.CAPTION_EXTRA_SM
        }
        typographyWeight={TypographyWeight._700}
      >
        {cardNumber.title}
      </Typography>
    </div>
  );
};

export default memo(WidgetCardNumber);
