import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

import { useLocalStore } from "@repo/data/hooks";
import {
  EmployeeStore,
  EmployeeStoreProvider,
} from "@repo/data/store/local/EmployeeStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import LayoutPage from "@app/pages/auth/LayoutPage";
import CollapseTabs from "@app/pages/employee/CollapseTabs";
import PersonalData from "@app/pages/employee/PersonalData";

import * as s from "./employee.module.scss";

export const Route = createFileRoute("/_auth/employee/$employeeId/")({
  component: observer(AuthEmployeePage),
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

function AuthEmployeePage(): JSX.Element {
  const { employeeId } = Route.useParams();

  const store = useLocalStore(() => new EmployeeStore(employeeId));

  if (store.meta.isLoading) {
    return <Loader isAbsolute size={LoaderSize.L} />;
  }

  return (
    <EmployeeStoreProvider store={store}>
      <LayoutPage className={s["employee"]}>
        <PersonalData />
        <CollapseTabs />
      </LayoutPage>
    </EmployeeStoreProvider>
  );
}
