import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useFilterOrgStructureStore } from "@repo/data/store/local/FilterStore";
import { DefaultFilterKey, type OptionWithKey } from "@repo/types/option";
import { LabelPath } from "@repo/ui/Label";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import ButtonOrgStructure from "../ButtonOrgStructure";

import * as s from "./HeaderSticky.module.scss";

export type HeaderStickyProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const HeaderSticky: React.FC<HeaderStickyProps> = ({
  className,
}: HeaderStickyProps) => {
  const { isPhone } = useWidth();
  const navigate = useNavigate();

  const filterStore = useFilterOrgStructureStore();

  const handleFilterSelect = useCallback(
    (filter: OptionWithKey) => {
      filterStore.setSelectedFilter(filter);

      navigate({
        to: "/employees/$orgId",
        params: { orgId: String(filter.key) },
      });
    },
    [filterStore, navigate],
  );

  return (
    <div className={clsx(s["header-sticky"], className)}>
      <div className={s["header-sticky__title"]}>
        <Typography
          typographyType={isPhone ? TypographyType.H3 : TypographyType.H1}
          typographyWeight={TypographyWeight._700}
        >
          Сотрудники
        </Typography>

        <ButtonOrgStructure className={s["header-sticky__button"]} />
      </div>

      <LabelPath
        defaultKey={DefaultFilterKey.EMPLOYEES}
        options={filterStore.filters}
        selected={filterStore.selectedFilter}
        onSelect={handleFilterSelect}
      />
    </div>
  );
};

export default observer(HeaderSticky);
