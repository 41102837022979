import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { type PropsWithChildren } from "react";

import Portal, { PortalSelector } from "@repo/ui/Portal";

import ModalClose from "./ModalClose";
import ModalContent from "./ModalContent";

import * as s from "./Modal.module.scss";

export type ModalProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Флаг видимости модального окна. */
  isOpened: boolean;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Обработчик закрытия модального окна. */
  onClose: () => void;
};

interface IModalComponent extends React.FC<PropsWithChildren<ModalProps>> {
  /** Вложенный компонент Detail. */
  Close: typeof ModalClose;
  /** Вложенный компонент Content. */
  Content: typeof ModalContent;
}

if (!document.getElementById(PortalSelector.MODALS)) {
  const el = document.createElement("div");
  el.setAttribute("id", PortalSelector.MODALS);
  document.body.prepend(el);
}

const Modal: IModalComponent = ({
  className,
  isOpened,
  isMobile,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Portal portalSelector={PortalSelector.MODALS}>
      <div className={clsx(s["modal"], isOpened && s["modal_opened"])}>
        <motion.div
          animate={{ opacity: isOpened ? 1 : 0 }}
          className={s["modal__veil"]}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        />

        <AnimatePresence>
          {isOpened && (
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              className={clsx(s["modal__layout"], className)}
              exit={{ opacity: 0, y: 40 }}
              initial={{ opacity: 0, y: 40 }}
              transition={{ duration: 0.3 }}
            >
              {isMobile && (
                <ModalClose className={s["modal__close"]} onClick={onClose} />
              )}

              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Portal>
  );
};

Modal.Close = ModalClose;
Modal.Content = ModalContent;

export default Modal;
