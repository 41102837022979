import { makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { RuleKey } from "@repo/data/config";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";

import { normalizeRule } from "./normalize";
import { InterfaceRuleType } from "./types";

type PrivateFields = "_rule";

class InterfaceRuleModel {
  private readonly _meta = new MetaStore();

  private _key: RuleKey;
  private _rule: InterfaceRuleType | null = null;

  constructor(key: RuleKey, rule: InterfaceRuleType | null = null) {
    this._key = key;
    this._rule = rule;

    makeObservable<this, PrivateFields>(this, {
      _rule: observable.ref,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get rule(): InterfaceRuleType | null {
    return this._rule;
  }

  async fetchRule(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.INTERFACE_RULE_ITEM,
      "GET",
      { data: { key: this._key } },
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._rule = normalizeRule(data);

      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default InterfaceRuleModel;
