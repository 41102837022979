import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Icon, { IconSize } from "@repo/ui/Icon";
import { InputFile } from "@repo/ui/Input";
import { TypographyType, TypographyWeight } from "@repo/ui/Typography";

import AvatarImage, { type AvatarImageProps } from "../AvatarImage";
import { AvatarSize } from "../config";

import * as s from "./AvatarProfile.module.scss";

export type AvatarProfileProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Обработчик изменения файла. */
  onChange: (files: FileList) => void;
} & Pick<AvatarImageProps, "src" | "alt">;

const AvatarProfile: React.FC<AvatarProfileProps> = ({
  className,
  isMobile,
  onChange,
  ...props
}: AvatarProfileProps) => {
  return (
    <div className={clsx(s["avatar"], className)}>
      <AvatarImage
        className={s["avatar__image"]}
        round={!isMobile}
        size={AvatarSize.UNSET}
        {...props}
      />

      <InputFile
        accept="image/*"
        className={s["avatar__button"]}
        typographyType={TypographyType.CAPS_MD}
        typographyWeight={TypographyWeight._700}
        onChange={onChange}
      >
        {isMobile ? (
          <Icon size={IconSize.XS} type={IconType.PHOTO} />
        ) : (
          "Изменить"
        )}
      </InputFile>
    </div>
  );
};

export default memo(AvatarProfile);
