import { action, computed, makeObservable, observable } from "mobx";

import type { ErrorType, FieldValue, Validator } from "@repo/types/form";

import type { FieldModelOptions, IFieldModel } from "./types";

type PrivateFields =
  | "_value"
  | "_required"
  | "_disable"
  | "_touched"
  | "_errors";

/**
 * Абстрактная модель поля формы.
 *
 * V - тип значения поля формы.
 * SV - тип значения поля формы, который получаем в setValue.
 */
abstract class AFormField<
  V extends FieldValue = string,
  SV extends FieldValue = V,
> implements IFieldModel<V, SV>
{
  protected readonly _name: string;

  protected readonly _initValue: V;
  protected readonly _initRequired: boolean;
  protected readonly _initDisable: boolean;

  protected _value: V;
  protected _required: boolean;
  protected _disable: boolean;

  protected _touched: boolean = false;
  protected _errors: ErrorType = [];

  protected _validate: Validator<V>;
  protected abstract _prepareValue(v: SV): V;

  constructor({
    name,
    value,
    required = false,
    disable = false,
  }: FieldModelOptions<V>) {
    this._name = name;

    this._initValue = value;
    this._initRequired = required;
    this._initDisable = disable;

    this._value = value;
    this._required = required;
    this._disable = disable;

    makeObservable<this, PrivateFields>(this, {
      _value: observable,
      _required: observable,
      _disable: observable,
      _touched: observable,
      _errors: observable.ref,

      errors: computed,
      errorMessage: computed,

      setValue: action.bound,
      setRequired: action.bound,
      setDisable: action.bound,
      setTouched: action.bound,
      setUntouched: action.bound,
      clear: action.bound,
      reset: action.bound,
    });
  }

  get name(): string {
    return this._name;
  }

  get value(): V {
    return this._value;
  }

  get required(): boolean {
    return this._required;
  }

  get disable(): boolean {
    return this._disable;
  }

  get touched(): boolean {
    return this._touched;
  }

  get hasError(): boolean {
    return Boolean(this.errors.length > 0);
  }

  get errors(): ErrorType {
    return this._validate?.(this._value) || this._errors;
  }

  get errorMessage(): string {
    return this.errors.join(", ");
  }

  setValue(value: SV): void {
    if (!this.touched) {
      this.setTouched();
    }

    this._value = this._prepareValue(value);
  }

  setRequired(value: boolean): void {
    this._required = value;
  }

  setDisable(value: boolean): void {
    this._disable = value;
  }

  setTouched(): void {
    this._touched = true;
  }

  setUntouched(): void {
    this._touched = false;
  }

  clear(): void {
    this._touched = false;
    this._errors = [];
  }

  reset(): void {
    this._value = this._initValue;
    this._required = this._initRequired;
    this._disable = this._initDisable;

    this.clear();
  }

  destroy(): void {
    this.clear();
  }
}

export default AFormField;
