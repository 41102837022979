import type { OrgStructureFlat } from "@repo/types/orgStructure";
import type { TreeData } from "@repo/types/tree";

function filterOrgStructureTree(
  data: OrgStructureFlat,
  searchValue = "",
): TreeData[] {
  return data
    .filter((unit) =>
      unit.depName.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .map((unit) => ({
      parentKey: unit.parentDepId,
      key: unit.depId,
      label: unit.depName,
      counter: unit.employeeCount,
      children: null,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default filterOrgStructureTree;
