import type { orgStructure } from "@repo/api/types";
import type {
  OrgStructureCrumbFlat,
  OrgStructureFlat,
} from "@repo/types/orgStructure";

export const normalizeOrgStructureCrumb = (
  rawData: orgStructure.OrgStructureCrumbApi,
): OrgStructureCrumbFlat => {
  if (!rawData) {
    return [];
  }

  return rawData.map((unit) => ({
    depId: String(unit.dep_id),
    depLevel: Number(unit.dep_level),
    depName: String(unit.dep_name),
  }));
};

export const normalizeOrgStructure = (
  rawData: orgStructure.OrgStructureApi,
): OrgStructureFlat =>
  rawData.map((unit) => ({
    depId: String(unit.dep_id),
    depLevel: Number(unit.dep_level),
    depName: String(unit.dep_name),
    employeeCount: unit.employee_count,
    employeeCountTotal: unit.employee_count_total,
    managerFullName: unit.manager_fio,
    managerNumber: unit.manager_tabnumber,
    managerPositionTable: unit.manager_position_table,
    managerPositionTableId: unit.manager_position_table_id,
    managerWorkStartDate: unit.manager_work_start_date,
    parentDepId: String(unit.parent_dep_id),
  }));
