import clsx from "clsx";
import { forwardRef, memo, useMemo } from "react";

import { IconType } from "@repo/types/icon";
import type { OptionWithKey } from "@repo/types/option";
import Icon, { IconDirection, IconSize } from "@repo/ui/Icon";
import { Input } from "@repo/ui/Input";

import * as s from "./SelectTrigger.module.scss";

export type SelectTriggerProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Плейсхолдер. */
  placeholder?: string;
  /** Если true, селект в открытом состоянии. */
  isOpened: boolean;
  /** Функция форматирования значения опции. */
  formatValue: (options: OptionWithKey[]) => string | React.ReactNode;
  /** Компонент для отображения опции. */
  ComponentOption: React.ComponentType<{ option: OptionWithKey }> | null;
  /** Список выбранных опций. */
  selectedOptions: OptionWithKey[];
  /** Обработчик потери фокуса. */
  onBlur?: () => void;
};

const SelectTrigger = forwardRef<HTMLDivElement, SelectTriggerProps>(
  (
    {
      className,
      placeholder,
      isOpened,
      formatValue,
      ComponentOption,
      selectedOptions,
      onBlur,
      ...props
    },
    forwardRef,
  ) => {
    const InputIcon = useMemo(
      () => (
        <Icon
          className={s["trigger__icon"]}
          direction={isOpened ? IconDirection.DOWN : IconDirection.UP}
          size={IconSize.M}
          type={IconType.ARROW_DOWN}
        />
      ),
      [isOpened],
    );

    return (
      <div
        ref={forwardRef}
        className={clsx(s["trigger"], className)}
        {...props}
      >
        <Input
          afterSlot={InputIcon}
          className={s["trigger__input"]}
          placeholder={placeholder}
          onBlur={onBlur}
        >
          {({ className, ref, handleChange, ...props }) => (
            <div className={s["trigger__content"]}>
              {!selectedOptions.length && (
                <input
                  ref={ref as React.RefObject<HTMLInputElement>}
                  readOnly
                  className={clsx(s["trigger__field"], className)}
                  onChange={handleChange}
                  {...props}
                />
              )}

              {ComponentOption
                ? selectedOptions.map((option) => (
                    <ComponentOption key={option.key} option={option} />
                  ))
                : formatValue(selectedOptions)}
            </div>
          )}
        </Input>
      </div>
    );
  },
);

SelectTrigger.displayName = "SelectTrigger";

export default memo(SelectTrigger);
