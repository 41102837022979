import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import * as s from "./TabContent.module.scss";

export type TabContentProps<K extends string = string> = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Ключ вкладки. */
  tabKey: K;
  /** Ключ выбранной вкладки. */
  selectedTabKey: K;
};

function TabContent<K extends string = string>({
  className,
  tabKey,
  selectedTabKey,
  children,
}: PropsWithChildren<TabContentProps<K>>) {
  if (tabKey !== selectedTabKey) {
    return null;
  }

  return <div className={clsx(s["tab-content"], className)}>{children}</div>;
}

export default memo(TabContent);
