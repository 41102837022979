import { createFileRoute } from "@tanstack/react-router";

import Loader, { LoaderSize } from "@repo/ui/Loader";

import LayoutPage from "@app/pages/auth/LayoutPage";
import CollapseTabs from "@app/pages/profile/CollapseTabs";
import PersonalData from "@app/pages/profile/PersonalData";

import * as s from "./profile.module.scss";

export const Route = createFileRoute("/_auth/profile/")({
  component: AuthProfilePage,
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

function AuthProfilePage(): JSX.Element {
  return (
    <LayoutPage className={s["profile"]}>
      <PersonalData />
      <CollapseTabs />
    </LayoutPage>
  );
}
