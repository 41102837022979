import { getDate, getMonth } from "date-fns";
import { memo, useMemo } from "react";

import { dayID, type DayID, getCalendar, WEEK_NAMES } from "@repo/utils/dates";

import CalendarCellDay from "./CalendarCellDay";
import CalendarCellWeekName from "./CalendarCellWeekName";

import * as s from "./Calendar.module.scss";

type Props = {
  /** Текущая дата */
  date: Date;
  /** Смены */
  shifts: Set<DayID>;
};

const Calendar: React.FC<Props> = ({ date, shifts }) => {
  const calendarDays = useMemo(() => getCalendar(date), [date]);
  const activeMonth = useMemo(() => getMonth(date), [date]);
  const activeDay = useMemo(() => getDate(date), [date]);

  return (
    <div className={s["root"]}>
      {WEEK_NAMES.map((weekName) => (
        <CalendarCellWeekName key={weekName} weekName={weekName} />
      ))}

      {calendarDays.map((value) => {
        const dayId = dayID(value);
        const isShift = shifts.has(dayId);
        return (
          <CalendarCellDay
            key={dayId}
            activeDay={activeDay}
            activeMonth={activeMonth}
            date={value}
            isShift={isShift}
          />
        );
      })}
    </div>
  );
};

export default memo(Calendar);
