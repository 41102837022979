import FormFieldMask from "./FormFieldMask";
import FormFieldNumber from "./FormFieldNumber";
import FormFieldPassword from "./FormFieldPassword";
import FormFieldSelect from "./FormFieldSelect";
import FormFieldText from "./FormFieldText";
import type { FieldModel, FieldModelGuard } from "./types";

export const isFormModelMask: FieldModelGuard<FormFieldMask> = (
  field: FieldModel,
): field is FormFieldMask => field instanceof FormFieldMask;

export const isFormModelNumber: FieldModelGuard<FormFieldNumber> = (
  field: FieldModel,
): field is FormFieldNumber => field instanceof FormFieldNumber;

export const isFormModelPassword: FieldModelGuard<FormFieldPassword> = (
  field: FieldModel,
): field is FormFieldPassword => field instanceof FormFieldPassword;

export const isFormModelSelect: FieldModelGuard<FormFieldSelect> = (
  field: FieldModel,
): field is FormFieldSelect => field instanceof FormFieldSelect;

export const isFormModelText: FieldModelGuard<FormFieldText> = (
  field: FieldModel,
): field is FormFieldText => field instanceof FormFieldText;
