import { createContext, useContext } from "react";

import { usePopover } from "./hooks";

type PopoverContextType = ReturnType<typeof usePopover> | null;

export const PopoverContext = createContext<PopoverContextType>(null);

export function usePopoverContext() {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error(
      "usePopoverContext must be used within a PopoverContext.Provider",
    );
  }

  return context;
}
