import clsx from "clsx";
import { Fragment, memo } from "react";

import type { DefaultFilterKey, OptionWithKey } from "@repo/types/option";
import Tag, { TagColor, TagSize } from "@repo/ui/Tag";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./LabelPath.module.scss";

export type LabelPathProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Список лейблов. */
  options: OptionWithKey[];
  /** Размер лейбла. */
  size?: TagSize;
  /** Разделитель между лейблами. */
  separator?: string;
  /** Ключ лейбла по умолчанию. */
  defaultKey?: DefaultFilterKey;
  /** Выбранный лейбл. */
  selected?: OptionWithKey | null;
  /** Обработчик клика по лейблу. */
  onSelect?: (value: OptionWithKey) => void;
};

const LabelPath: React.FC<LabelPathProps> = ({
  className,
  options,
  size = TagSize.XS,
  separator,
  defaultKey,
  selected,
  onSelect,
}: LabelPathProps) => {
  return (
    <div
      className={clsx(
        s["labels"],
        separator && s["labels__with-separator"],
        className,
      )}
    >
      {options.map((option, index, arr) => (
        <Fragment key={option.key}>
          <Tag
            color={option.key === defaultKey ? TagColor.PRIMARY : TagColor.GRAY}
            label={option.label}
            selected={option.key === selected?.key}
            size={size}
            value={option}
            onClick={onSelect}
          />

          {separator && index !== arr.length - 1 && (
            <Typography
              Component="span"
              typographyType={TypographyType.BODY_MD}
              typographyWeight={TypographyWeight._500}
            >
              {separator}
            </Typography>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(LabelPath);
