import {
  maskitoInitialCalibrationPlugin,
  type MaskitoOptions,
} from "@maskito/core";

export enum MaskitoMask {
  /** Форма логина: телефон */
  PHONE = "phone",
  /** Форма редактирования профиля: Whatsapp */
  WHATSAPP = "whatsapp",
}

export const MASKITO_MASK_OPTIONS: Record<MaskitoMask, MaskitoOptions> = {
  [MaskitoMask.PHONE]: {
    mask: [
      "+",
      "7",
      " ",
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ],
  },
  [MaskitoMask.WHATSAPP]: {
    mask: [
      "+",
      "7",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ],
    plugins: [maskitoInitialCalibrationPlugin()],
  },
};
