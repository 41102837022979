import type { Validator } from "@repo/types/form";
import {
  composeValidators,
  ErrorMessage,
  validateLength,
  validateNotEmpty,
} from "@repo/utils/validator";

import AFormField from "../AFormField";
import type { FieldModelOptions } from "../AFormField";

class FormFieldPassword<V extends string = string> extends AFormField<V> {
  private static _MIN_LENGTH_PASSWORD: number = 6;

  constructor(options: FieldModelOptions<V>) {
    super({ ...options });
  }

  protected _prepareValue(value: V): V {
    return value;
  }

  protected _validator(): Validator<V> {
    return composeValidators(
      validateNotEmpty(ErrorMessage.REQUIRED_FIELD),
      validateLength(
        FormFieldPassword._MIN_LENGTH_PASSWORD,
        ErrorMessage.MIN_LENGTH_PASSWORD,
      ),
    );
  }

  destroy(): void {
    super.destroy();
  }
}

export default FormFieldPassword;
