import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const MinusIcon: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M19 11H5c-.26522 0-.51957.1054-.70711.2929C4.10536 11.4804 4 11.7348 4 12c0 .2652.10536.5196.29289.7071C4.48043 12.8946 4.73478 13 5 13h14c.2652 0 .5196-.1054.7071-.2929S20 12.2652 20 12c0-.2652-.1054-.5196-.2929-.7071S19.2652 11 19 11Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(MinusIcon);
