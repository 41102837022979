import type {
  ApiPath,
  FetchApiOptions,
  GetFetchApiData,
  GetFetchApiMethod,
  GetFetchApiPathData,
} from "../fetchApi";
import { combineMiddleware } from "../utils";

import callFetchApi from "./middleware/callFetchApi";
import type { ApiOrgStructureResult } from "./types";

const middleware = combineMiddleware([], callFetchApi);

interface IApiOrgStructure {
  <P extends ApiPath, M extends GetFetchApiMethod<P>>(
    path: P,
    method: M,
    options?: FetchApiOptions<GetFetchApiPathData<P, M>, GetFetchApiData<P, M>>,
  ): ApiOrgStructureResult<P, M>;
}

const apiOrgStructure: IApiOrgStructure = (path, method, options) =>
  middleware({ path, method, options });

export default apiOrgStructure;
