import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { lazy, Suspense, useCallback } from "react";

import { RuleKey, Theme } from "@repo/data/config";
import { useInterfaceRule } from "@repo/data/store/single/GlobalInterfaceRuleStore";
import GlobalThemeStore from "@repo/data/store/single/GlobalThemeStore";
import { useUser } from "@repo/data/store/single/GlobalUserStore";
import { IconType } from "@repo/types/icon";
import Button, { ButtonSize } from "@repo/ui/Button";
import Icon from "@repo/ui/Icon";
import { SwitchTheme } from "@repo/ui/Switch";

import ProfileLink from "./components/ProfileLink";

import * as s from "./Header.module.scss";

export type HeaderProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const Notifications = lazy(() => import("./components/Notifications"));

const Header: React.FC<HeaderProps> = ({ className }: HeaderProps) => {
  const themeStore = GlobalThemeStore.getInstance();

  const user = useUser();
  const notificationRule = useInterfaceRule(RuleKey.NOTIFICATION);

  const handleThemeChange = useCallback(() => {
    themeStore.setTheme(themeStore.isLight ? Theme.DARK : Theme.LIGHT);
  }, [themeStore]);

  return (
    <header className={clsx(s["header"], className)}>
      <Button.Base
        before={<Icon type={IconType.PLUS} />}
        className={s["header__request"]}
        size={ButtonSize.MEDIUM}
      >
        Создать заявку
      </Button.Base>

      <div className={s["header__controls"]}>
        <SwitchTheme
          checked={themeStore.isLight}
          className={s["header__switch-theme"]}
          onChange={handleThemeChange}
        />

        {notificationRule && (
          <Suspense fallback={null}>
            <Notifications />
          </Suspense>
        )}
      </div>

      <ProfileLink avatar={user.avatar} username={user.fullName} />
    </header>
  );
};

export default observer(Header);
