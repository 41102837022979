import type { OptionWithKey } from "./types";

export enum DefaultFilterKey {
  EMPLOYEES = "all-employees",
}

export const OPTION_ALL_EMPLOYEE: OptionWithKey<string> = {
  key: DefaultFilterKey.EMPLOYEES,
  label: "Все сотрудники",
};
