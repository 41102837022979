import clsx from "clsx";
import { memo, type PropsWithChildren, useCallback } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./InputFile.module.scss";

export type InputFileProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Тип шрифта. */
  typographyType: TypographyType;
  /** Толщина шрифта. */
  typographyWeight: TypographyWeight;
  /** Типы файлов, которые можно загрузить. */
  accept?: string;
  /** Обработчик изменения файла. */
  onChange: (files: FileList) => void;
};

const InputFile: React.FC<PropsWithChildren<InputFileProps>> = ({
  className,
  typographyType,
  typographyWeight,
  accept,
  onChange,
  children,
}: PropsWithChildren<InputFileProps>) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onChange(e.target.files);
      }

      e.target.value = "";
    },
    [onChange],
  );

  return (
    <Typography
      Component="label"
      className={clsx(s["file"], className)}
      typographyType={typographyType}
      typographyWeight={typographyWeight}
    >
      <input
        accept={accept}
        className={s["file__input"]}
        type="file"
        onChange={handleChange}
      />

      {children}
    </Typography>
  );
};

export default memo(InputFile);
