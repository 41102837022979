import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Popover, { TRANSITION_VARIANT } from "@repo/ui/Popover";

import useWidth from "@app/hooks/useWidth";

import Sidebar from "../Sidebar";

import MenuMobileButton from "./components/MenuMobileButton";
import MenuMobileItem from "./components/MenuMobileItem";

import * as s from "./MenuMobile.module.scss";

export type MenuMobileProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const MenuMobile: React.FC<MenuMobileProps> = ({
  className,
}: MenuMobileProps) => {
  const { isPhone } = useWidth();

  return (
    <div className={clsx(s["root"], className)}>
      <MenuMobileItem iconType={IconType.HOUSE_3D} linkTo="/" title="Главная" />

      <MenuMobileItem
        counter={{
          count: "2",
        }}
        iconType={IconType.CHECK_PAPER_3D}
        linkTo="/profile"
        title="Задачи"
      />

      <MenuMobileButton className={s["root__button"]} />

      <MenuMobileItem
        counter={{
          count: "4",
          allCount: "2",
        }}
        iconType={IconType.ARROW_DOWN_3D}
        linkTo="/profile"
        title="Заявки"
      />

      <Popover modal withOverlay>
        <Popover.Trigger>
          {(props) => (
            <MenuMobileItem
              preventDefault
              iconType={IconType.MENU_3D}
              linkTo="/menu"
              title="Меню"
              {...props}
            />
          )}
        </Popover.Trigger>

        <Popover.Content
          transition={
            isPhone
              ? TRANSITION_VARIANT.PHONE_WITH_OVERLAY
              : TRANSITION_VARIANT.DEFAULT
          }
        >
          <Sidebar className={s["root__menu"]} />

          <Popover.Close />
        </Popover.Content>
      </Popover>
    </div>
  );
};

export default memo(MenuMobile);
