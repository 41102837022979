import type { EmployeeTypeValue } from "@repo/types/employee";
import type { UserTypeValue } from "@repo/types/user";
import { formatDateDDMMMM, formatPhoneNumber } from "@repo/utils/format";

export enum Detail {
  email = "email",
  phone = "phone",
  tg = "tg",
  vk = "vk",
  whatsapp = "whatsapp",
  department = "department",
  position = "position",
  birthday = "birthday",
  employeeNumber = "employeeNumber",
  statusMessage = "statusMessage",
  description = "description",
}

export const DetailMap: Record<
  Detail,
  {
    key: Detail;
    label: string;
    formatFn?: (value: UserTypeValue | EmployeeTypeValue) => string;
  }
> = {
  [Detail.email]: {
    key: Detail.email,
    label: "Электронная почта",
  },
  [Detail.phone]: {
    key: Detail.phone,
    label: "Телефон",
    formatFn: formatPhoneNumber,
  },
  [Detail.tg]: {
    key: Detail.tg,
    label: "Telegram",
  },
  [Detail.vk]: {
    key: Detail.vk,
    label: "VK",
  },
  [Detail.whatsapp]: {
    key: Detail.whatsapp,
    label: "WhatsApp",
    formatFn: formatPhoneNumber,
  },
  [Detail.department]: {
    key: Detail.department,
    label: "Подразделение",
  },
  [Detail.position]: {
    key: Detail.position,
    label: "Должность",
  },
  [Detail.birthday]: {
    key: Detail.birthday,
    label: "Дата рождения",
    formatFn: formatDateDDMMMM,
  },
  [Detail.employeeNumber]: {
    key: Detail.employeeNumber,
    label: "Табельный номер",
  },
  [Detail.statusMessage]: {
    key: Detail.statusMessage,
    label: "Статус",
  },
  [Detail.description]: {
    key: Detail.description,
    label: "Обо мне",
  },
};
