import { ContactType } from "@repo/types/contact";

export enum ProfileEditField {
  /** Статус сотрудника. */
  STATUS = "profile-status",
  /** Список тегов сотрудника. */
  TAGS = "profile-tags",
  /** О сотруднике. */
  ABOUT = "profile-about",
  /** Ссылка на профиль в VK. */
  VK = "profile-vk",
  /** Ссылка на профиль в Telegram. */
  TG = "profile-tg",
  /** Ссылка на профиль в WhatsApp. */
  WHATSAPP = "profile-whatsapp",
}

export const CONTACT_MAP = {
  [ProfileEditField.VK]: ContactType.VK,
  [ProfileEditField.TG]: ContactType.TG,
  [ProfileEditField.WHATSAPP]: ContactType.WHATSAPP,
} as const;
