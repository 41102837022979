import type { user } from "@repo/api/types";
import type { UserType } from "@repo/types/user";

import S3ResourceModel from "../../models/S3ResourceModel";

import { ORG_STRUCTURE_MOCK } from "./__mocks__";

export const normalizeUser = (rawData: user.UserCurrentApi): UserType => {
  const avatarURL = rawData.avatar
    ? new S3ResourceModel(rawData.avatar).url
    : null;

  const links = rawData.contacts.reduce<Record<string, string>>((acc, item) => {
    const key = item.type.toLowerCase();
    acc[key] = item.link;

    return acc;
  }, {});

  return {
    avatar: avatarURL,
    contacts: rawData.contacts,
    description: rawData.description,
    email: rawData.email,
    employeeNumber: rawData.employee_tabnumber,
    fullName: rawData.fio,
    labels: rawData.labels,
    orgStructure: ORG_STRUCTURE_MOCK,
    phone: rawData.phone,
    position: rawData.position,
    statusMessage: rawData.status_message,
    userId: rawData.user_id,
    userName: rawData.username,

    ...links,
  };
};
