import type { OrgStructureUnit } from "@repo/types/orgStructure";

export const ORG_STRUCTURE_MOCK: OrgStructureUnit = {
  depId: "divizion-samara",
  depLevel: 0,
  depName: "Дивизион Самара",
  employeeCount: "1",
  employeeCountTotal: "1",
  managerFullName: null,
  managerNumber: null,
  managerPositionTable: null,
  managerPositionTableId: null,
  managerWorkStartDate: null,
  parentDepId: "",
};
