/**
 * Тип контакта соответствует перечислению на backend.
 */
export enum ContactType {
  /** Ссылка на профиль в VK. */
  VK = "VK",
  /** Ссылка на профиль в Telegram. */
  TG = "TG",
  /** Ссылка на профиль в WhatsApp. */
  WHATSAPP = "WHATSAPP",
}
