import {
  type FormFieldSelect,
  isFormModelSelect,
} from "@repo/data/store/models/FormModel";
import Select, { SelectProps } from "@repo/ui/Select";
import WithError from "@repo/ui/WithError";

import { withField } from "../../hoc";

export type FieldSelectProps = {
  /** Имя поля формы. */
  name: string;
  /** Модель поля формы. */
  field: FormFieldSelect;
} & Omit<SelectProps, "selectedOptions" | "onChange">;

const BaseFieldSelect: React.FC<FieldSelectProps> = ({
  className,
  field,
  ...props
}: FieldSelectProps) => {
  return (
    <WithError className={className} message={field.errorMessage}>
      <Select
        selectedOptions={field.value}
        onBlur={field.setTouched}
        onChange={field.setValue}
        {...props}
      />
    </WithError>
  );
};

export const FieldSelect = withField(BaseFieldSelect, isFormModelSelect);
