export enum InputSearchSize {
  /** height: 40px */
  SMALL = "small",
  /** height: 44/54px */
  MEDIUM = "medium",
  /** height: 56/64px */
  LARGE = "large",
}

export enum InputSearchTheme {
  /** Основной стиль. */
  MAIN = "main",
  /** Стиль для вложенных страниц. */
  SECONDARY = "secondary",
}
