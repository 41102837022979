import clsx from "clsx";
import { memo, useCallback, type PropsWithChildren } from "react";

import type { IFormModel } from "@repo/data/store/models/FormModel";

import { FormContext } from "./context";

import * as s from "./Form.module.scss";

export type FormProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Модель формы. */
  store: IFormModel;
  /** Обработчик события отправки формы. */
  onSubmit?: (e: React.FormEvent) => void;
};

const Form: React.FC<PropsWithChildren<FormProps>> = ({
  className,
  store,
  onSubmit,
  children,
}: PropsWithChildren<FormProps>) => {
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      await store.submit();

      onSubmit?.(event);
    },
    [store, onSubmit],
  );

  return (
    <FormContext.Provider value={store}>
      <form className={clsx(s["form"], className)} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export default memo(Form);
