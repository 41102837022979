import clsx from "clsx";
import { ForwardedRef, forwardRef, type PropsWithChildren } from "react";

import { TypographyType, TypographyWeight } from "./config";

import * as s from "./Typography.module.scss";

type JSXElementsString = keyof JSX.IntrinsicElements;
type ComponentType =
  | JSXElementsString
  | React.ComponentType<{ className?: string }>;

type GetPropsJSXElement<C extends ComponentType> = C extends JSXElementsString
  ? JSX.IntrinsicElements[C]
  : React.ComponentProps<C>;

export type TypographyProps<C extends ComponentType> = {
  /** Компонент для обертки children */
  Component?: C;
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Тип шрифта. */
  typographyType: TypographyType;
  /** Толщина шрифта. */
  typographyWeight: TypographyWeight;
} & GetPropsJSXElement<C>;

function Typography<C extends ComponentType>(
  {
    className,
    children,
    typographyType,
    typographyWeight,
    Component = "div" as C,
    ...props
  }: PropsWithChildren<TypographyProps<C>>,
  ref: ForwardedRef<any>,
) {
  return (
    // @ts-expect-error Немного ломается из-за смещения типов, принудитель скипаем
    <Component
      // @ts-expect-error Немного ломается из-за смещения типов, принудитель скипаем
      ref={ref}
      className={clsx(
        s.typography,
        s[`typography_${typographyType}`],
        s[`typography_weight-${typographyWeight}`],
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

export default forwardRef(Typography) as typeof Typography;
