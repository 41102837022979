import TabContent from "./TabContent";
import TabsContainer from "./Tabs";

const Tabs = Object.assign(TabsContainer, {
  Base: TabsContainer,
  Content: TabContent,
});

export default Tabs;

export type { TabContentProps } from "./TabContent";
export type { TabsProps } from "./Tabs";
export type { TabItem } from "./types";
