import { createContextLocalStore } from "../createContextLocalStore";

import LabelStore from "./LabelStore";

const { Provider, useStore } = createContextLocalStore(LabelStore);

export {
  Provider as LabelStoreProvider,
  useStore as useLabelStore,
  LabelStore,
};
