import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { memo, type PropsWithChildren } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./WithError.module.scss";

export type WithErrorProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Сообщение об ошибке, которое будет отображаться. */
  message?: string;
  /** Тип шрифта. */
  typographyType?: TypographyType;
};

export const WithError: React.FC<PropsWithChildren<WithErrorProps>> = ({
  className,
  message,
  typographyType = TypographyType.CAPTION,
  children,
}: PropsWithChildren<WithErrorProps>) => {
  return (
    <div className={s["error"]}>
      {children}

      <AnimatePresence>
        {Boolean(message) && (
          <motion.div
            animate={{ opacity: 1, height: "auto" }}
            className={clsx(s["error__message"], className)}
            exit={{ opacity: 0, height: 0, marginTop: 0 }}
            initial={{ opacity: 0, height: 0, marginTop: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Typography
              Component="p"
              className={s["error__text"]}
              typographyType={typographyType}
              typographyWeight={TypographyWeight._500}
            >
              {message}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(WithError);
