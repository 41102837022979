import { createContext, useContext } from "react";

import type { IFormModel } from "@repo/data/store/models/FormModel";

type FormContextType = IFormModel | null;

export const FormContext = createContext<FormContextType>(null);

export function useFormContext() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error(
      "useFormContext must be used within a FormContext.Provider",
    );
  }

  return context;
}
