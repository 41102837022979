import { createFileRoute, Navigate } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

import { useUser } from "@repo/data/store/single/GlobalUserStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

export const Route = createFileRoute("/_auth/employees/")({
  component: observer(AuthEmployeesPage),
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

function AuthEmployeesPage(): JSX.Element {
  const user = useUser();

  return (
    <Navigate
      replace
      params={{ orgId: user.orgStructure.orgUnitId }}
      to="/employees/$orgId"
    />
  );
}
