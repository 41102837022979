import { memo, useRef } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import useWidth from "@app/hooks/useWidth";

import { CHART_LEGEND_EXTRA_MAP, CHART_LEGEND_MAP } from "../ChartLegend";

import type { ChartData, ChartExtraData } from "./types";
import { usePieChartConfig } from "./usePieChartConfig";

export type ChartProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Данные для графика */
  data: ChartData[];
  /** Дополнительные данные для графика */
  extraData?: ChartExtraData[];
};

const Chart: React.FC<ChartProps> = ({
  className,
  data,
  extraData,
}: ChartProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isDesktop } = useWidth();

  const { pieChartSize, mainPieConfig, extraPieConfig } =
    usePieChartConfig(containerRef);

  return (
    <div ref={containerRef} className={className}>
      <ResponsiveContainer
        height={pieChartSize.height}
        maxHeight={isDesktop ? 360 : 200}
        width={pieChartSize.width}
      >
        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <Pie
            blendStroke
            cornerRadius={mainPieConfig.cornerRadius}
            cx={mainPieConfig.cx}
            cy={mainPieConfig.cy}
            data={data}
            dataKey="value"
            endAngle={0}
            innerRadius={mainPieConfig.innerRadius}
            nameKey="name"
            outerRadius={mainPieConfig.outerRadius}
            paddingAngle={0.4}
            startAngle={180}
          >
            {data.map((entry) => (
              <Cell
                key={entry.name}
                fill={CHART_LEGEND_MAP[entry.name]?.color}
              />
            ))}
          </Pie>

          {extraData && (
            <Pie
              blendStroke
              cornerRadius={extraPieConfig.cornerRadius}
              cx={extraPieConfig.cx}
              cy={extraPieConfig.cy}
              data={extraData}
              dataKey="value"
              endAngle={0}
              innerRadius={extraPieConfig.innerRadius}
              nameKey="name"
              outerRadius={extraPieConfig.outerRadius}
              paddingAngle={0.4}
              startAngle={180}
            >
              {extraData.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={CHART_LEGEND_EXTRA_MAP[entry.name]?.color}
                />
              ))}
            </Pie>
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(Chart);
