import { observer } from "mobx-react-lite";

import type {
  FieldModel,
  FieldModelGuard,
} from "@repo/data/store/models/FormModel";

import { useFormContext } from "../context";

export function withField<
  P extends { field: F; name: string },
  F extends FieldModel,
>(
  Component: React.FunctionComponent<P>,
  isModelCorrect: FieldModelGuard<F>,
): React.FC<Omit<P, "field">> {
  const ObservedComponent = observer(Component);

  const ComponentWithField: React.FC<Omit<P, "field">> = ({
    name,
    ...props
  }) => {
    const formStore = useFormContext();

    const field = formStore.getField<F>(name, isModelCorrect);

    if (!field) {
      return null;
    }

    return <ObservedComponent {...(props as P)} field={field} />;
  };

  ComponentWithField.displayName = `${Component.displayName}WithField`;

  return ComponentWithField;
}
