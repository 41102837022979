// extracted by mini-css-extract-plugin
var _1 = "avatar__zQXZ4";
var _2 = "avatar__image__Ykxeg";
var _3 = "avatar_border__V3YAG";
var _4 = "avatar_round__Cc3ZN";
var _5 = "avatar_size-m__RKDIc";
var _6 = "avatar_size-s__Unfxs";
var _7 = "avatar_size-unset__Mea1I";
var _8 = "avatar_size-xs__rpOCU";
export { _1 as "avatar", _2 as "avatar__image", _3 as "avatar_border", _4 as "avatar_round", _5 as "avatar_size-m", _6 as "avatar_size-s", _7 as "avatar_size-unset", _8 as "avatar_size-xs" }
