import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useLocalStore } from "@repo/data/hooks";
import { AvatarChangeStore } from "@repo/data/store/local/AvatarChangeStore";
import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import { useUser } from "@repo/data/store/single/GlobalUserStore";
import { IconType } from "@repo/types/icon";
import { AvatarProfile } from "@repo/ui/Avatar";
import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import { LabelList } from "@repo/ui/Label";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";

import DetailList, { useDetails } from "../../shared/DetailList";
import ModalAvatarChange from "../ModalAvatarChange";
import ModalProfileEdit from "../ModalProfileEdit";
import ProfileControls from "../ProfileControls";

import * as s from "./PersonalData.module.scss";

export type PersonalDataProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const PersonalData: React.FC<PersonalDataProps> = ({
  className,
}: PersonalDataProps) => {
  const { isPhone } = useWidth();

  const user = useUser();
  const details = useDetails(user);

  const avatarStore = useLocalStore(() => new AvatarChangeStore());

  const modalAvatarChange = useModalsStoreOpenState(
    ModalsBaseKeys.AVATAR_CHANGE,
  );
  const modalProfileEdit = useModalsStoreOpenState(ModalsBaseKeys.PROFILE_EDIT);

  const handleAvatarChange = useCallback(
    async (files: FileList) => {
      const [photo] = files;

      if (!photo) {
        return;
      }

      await avatarStore.setImage(photo);

      modalAvatarChange.open();
    },
    [avatarStore, modalAvatarChange],
  );

  return (
    <div className={clsx(s["personal"], className)}>
      <AvatarProfile
        alt={user.fullName}
        className={s["personal__avatar"]}
        isMobile={isPhone}
        src={user.avatar}
        onChange={handleAvatarChange}
      />

      <div className={s["personal__content"]}>
        <LabelList className={s["personal__status"]} labels={user.labels} />

        <Typography
          Component="h1"
          className={s["personal__title"]}
          typographyType={TypographyType.H1}
          typographyWeight={TypographyWeight._700}
        >
          {user.fullName}
        </Typography>

        <DetailList className={s["personal__details"]} details={details} />

        <div className={s["personal__controls"]}>
          <ProfileControls />

          <Button.Text
            className={s["personal__edit"]}
            color={ButtonColor.DEFAULT}
            onClick={modalProfileEdit.open}
          >
            {isPhone ? (
              <Icon size={IconSize.XS} type={IconType.EDIT} />
            ) : (
              <Typography
                Component="span"
                typographyType={TypographyType.BTN_SM}
                typographyWeight={TypographyWeight._700}
              >
                Редактировать
              </Typography>
            )}
          </Button.Text>
        </div>
      </div>

      <ModalAvatarChange store={avatarStore} />
      <ModalProfileEdit />
    </div>
  );
};

export default observer(PersonalData);
