import { parsePhoneNumberFromString } from "libphonenumber-js";

/**
 * Форматирует номер телефона в международном формате +7 999 999 99 99.
 *
 * @param {string} phone - Номер телефона в виде строки.
 * @returns {string} Отформатированный номер телефона.
 */
export const formatPhoneNumber = (phone: string): string => {
  const parsed = parsePhoneNumberFromString(phone, "RU");

  return parsed?.formatInternational() || phone;
};
