import clsx from "clsx";
import { memo } from "react";

import type { OptionWithKey } from "@repo/types/option";

import DetailItem from "./DetailItem";

import * as s from "./DetailList.module.scss";

export type DetailListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Дополнительные данные сотрудника. */
  details: OptionWithKey[];
};

const DetailList: React.FC<DetailListProps> = ({
  className,
  details,
}: DetailListProps) => {
  return (
    <div className={clsx(s["details"], className)}>
      {details.map((detail) => (
        <DetailItem
          key={detail.key}
          title={detail.label}
          value={String(detail.value)}
        />
      ))}
    </div>
  );
};

export default memo(DetailList);
