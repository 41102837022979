import { flip, type Middleware, offset, shift, size } from "@floating-ui/react";

/** Расстояние от SelectTrigger до SelectDropdown */
const SELECT_OFFSET = 12;

export const middleware: Middleware[] = [
  flip({
    fallbackAxisSideDirection: "end",
    padding: SELECT_OFFSET,
  }),
  offset(SELECT_OFFSET),
  shift({ padding: SELECT_OFFSET }),
  size({
    apply({ rects, elements }) {
      Object.assign(elements.floating.style, {
        minWidth: `${rects.reference.width}px`,
      });
    },
  }),
];
