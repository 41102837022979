import type { EmployeeCard } from "@repo/types/employee";
import type { OrgStructureFlat } from "@repo/types/orgStructure";

import avatarOne from "./employee-1.png";
import avatarTwo from "./employee-2.png";
import avatarThree from "./employee-3.png";

export const EMPLOYEE_MANAGER_DATA: EmployeeCard = {
  avatar: avatarOne,
  department: "Отдел закупок",
  email: "andreev@hello-hr.ru",
  employeeNumber: "1",
  employeePosition: "Руководитель отдела закупок",
  fullName: "Андреев Сергей Геннадиевич",
  isFired: false,
  isManager: true,
  labels: [
    {
      id: 1,
      title: "Label 1",
      color: "#422f7c",
    },
    {
      id: 2,
      title: "Label 2",
      color: "#daccff",
    },
  ],
  orgStructure: [
    {
      key: "lyubimaya-kompaniya",
      label: "Любимая компания",
    },
    {
      key: "filial-volga",
      label: "Филиал Волга",
    },
    {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
  ],
  orgStructureKey: {
    key: "divizion-samara",
    label: "Дивизион Самара",
  },
  phone: "+7 964 999-99-98",
  subordinates: [
    {
      avatar: avatarTwo,
      department: "Отдел закупок",
      email: "andreev@hello-hr.ru",
      employeeNumber: "1",
      employeePosition: "Руководитель отдела закупок",
      fullName: "Андреев Сергей Геннадиевич",
      isFired: false,
      isManager: true,
      labels: [
        {
          id: 1,
          title: "Label 1",
          color: "#422f7c",
        },
        {
          id: 2,
          title: "Label 2",
          color: "#daccff",
        },
      ],
      orgStructure: [
        {
          key: "lyubimaya-kompaniya",
          label: "Любимая компания",
        },
        {
          key: "filial-volga",
          label: "Филиал Волга",
        },
        {
          key: "divizion-samara",
          label: "Дивизион Самара",
        },
      ],
      orgStructureKey: {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
      phone: "+7 964 999-99-98",
      subordinates: [],
      userId: 1,
      userPosition: "Руководитель",
    },
  ],
  userId: 1,
  userPosition: "Руководитель",
};

export const EMPLOYEE_DATA: EmployeeCard[] = [
  {
    avatar: avatarOne,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "0004",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarTwo,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "2",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarThree,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "3",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarOne,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "4",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarTwo,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "5",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarThree,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "6",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarOne,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "7",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarTwo,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "8",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
  {
    avatar: avatarThree,
    department: "Отдел закупок",
    email: "andreev@hello-hr.ru",
    employeeNumber: "9",
    employeePosition: "Руководитель отдела закупок",
    fullName: "Андреев Сергей Геннадиевич",
    isFired: false,
    isManager: false,
    labels: [
      {
        id: 1,
        title: "Label 1",
        color: "#422f7c",
      },
      {
        id: 2,
        title: "Label 2",
        color: "#daccff",
      },
    ],
    orgStructure: [
      {
        key: "lyubimaya-kompaniya",
        label: "Любимая компания",
      },
      {
        key: "filial-volga",
        label: "Филиал Волга",
      },
      {
        key: "divizion-samara",
        label: "Дивизион Самара",
      },
    ],
    orgStructureKey: {
      key: "divizion-samara",
      label: "Дивизион Самара",
    },
    phone: "+7 964 999-99-98",
    subordinates: null,
    userId: 1,
    userPosition: "Руководитель",
  },
];

export const ORG_STRUCTURE_DATA: OrgStructureFlat = [
  {
    depId: "root",
    depLevel: 0,
    depName: "Хозяйственно-эксплуатационное управление",
    employeeCount: "1",
    employeeCountTotal: "1",
    managerFullName: null,
    managerNumber: null,
    managerPositionTable: null,
    managerPositionTableId: null,
    managerWorkStartDate: null,
    parentDepId: "",
  },
  {
    depId: "1",
    depLevel: 1,
    depName: "Хозяйственный отдел",
    employeeCount: "1",
    employeeCountTotal: "1",
    managerFullName: null,
    managerNumber: null,
    managerPositionTable: null,
    managerPositionTableId: null,
    managerWorkStartDate: null,
    parentDepId: "root",
  },
];
