import {
  type FormFieldPassword,
  isFormModelPassword,
} from "@repo/data/store/models/FormModel";
import { type InputBaseProps, InputPassword } from "@repo/ui/Input";
import WithError from "@repo/ui/WithError";

import { withField } from "../../hoc";

export type FieldPasswordProps = {
  /** Имя поля формы. */
  name: string;
  /** Модель поля формы. */
  field: FormFieldPassword;
} & InputBaseProps;

const BaseFieldPassword: React.FC<FieldPasswordProps> = ({
  className,
  field,
  ...props
}: FieldPasswordProps) => {
  return (
    <WithError className={className} message={field.errorMessage}>
      <InputPassword
        {...props}
        disabled={field.disable}
        error={field.hasError}
        name={field.name}
        value={field.value}
        onBlur={field.setTouched}
        onChange={field.setValue}
      />
    </WithError>
  );
};

export const FieldPassword = withField(BaseFieldPassword, isFormModelPassword);
