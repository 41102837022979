import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./CardDetail.module.scss";

export type CardDetailProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Заголовок. */
  title: string;
  /** Мобильная версия. */
  isMobile: boolean;
};

const CardDetail: React.FC<PropsWithChildren<CardDetailProps>> = ({
  className,
  title,
  children,
  isMobile,
}: PropsWithChildren<CardDetailProps>) => {
  return (
    <div className={clsx(s["card-detail"], className)}>
      <Typography
        Component="p"
        className={s["card-detail__title"]}
        typographyType={
          isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
        }
        typographyWeight={
          isMobile ? TypographyWeight._500 : TypographyWeight._400
        }
      >
        {title}
      </Typography>

      <Typography
        Component="div"
        className={s["card-detail__text"]}
        typographyType={
          isMobile ? TypographyType.CAPTION : TypographyType.BODY_MD
        }
        typographyWeight={TypographyWeight._500}
      >
        {children}
      </Typography>
    </div>
  );
};

export default memo(CardDetail);
