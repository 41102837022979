import clsx from "clsx";
import { motion } from "framer-motion";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Icon, { IconSize } from "@repo/ui/Icon";
import { InputFile } from "@repo/ui/Input";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import AvatarImage, { type AvatarImageProps } from "../AvatarImage";
import { AvatarSize } from "../config";

import * as s from "./AvatarEdit.module.scss";

export type AvatarEditProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Ссылка на изображение. */
  src: string | null;
  /** Заголовок. */
  title?: string;
  /** Требования к загружаемому фото. */
  description?: string;
  /** Callback, вызывается при изменении фото. */
  onChange: (files: FileList) => void;
} & Pick<AvatarImageProps, "alt">;

const AvatarEdit: React.FC<AvatarEditProps> = ({
  className,
  src,
  title,
  description,
  onChange,
  ...props
}: AvatarEditProps) => {
  return (
    <div className={clsx(s["avatar"], className)}>
      <InputFile
        accept="image/*"
        className={s["avatar__image"]}
        typographyType={TypographyType.CAPS_MD}
        typographyWeight={TypographyWeight._700}
        onChange={onChange}
      >
        {src ? (
          <AvatarImage round size={AvatarSize.UNSET} src={src} {...props} />
        ) : (
          <div className={s["avatar__placeholder"]}>
            <Icon size={IconSize.M} type={IconType.PLUS} />
          </div>
        )}

        {src && (
          <motion.div
            className={s["avatar__veil"]}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            whileHover={{ opacity: 1 }}
          >
            <Icon size={IconSize.M} type={IconType.EDIT} />
          </motion.div>
        )}
      </InputFile>

      <div className={s["avatar__content"]}>
        <Typography
          Component="p"
          className={s["avatar__title"]}
          typographyType={TypographyType.BODY_LG}
          typographyWeight={TypographyWeight._500}
        >
          {title ? title : "Фото профиля"}
        </Typography>

        <Typography
          Component="p"
          className={s["avatar__description"]}
          typographyType={TypographyType.BODY_SM}
          typographyWeight={TypographyWeight._400}
        >
          {description
            ? description
            : "Формат изображения — квадратный, до\u00A01024x1024"}
        </Typography>
      </div>
    </div>
  );
};

export default memo(AvatarEdit);
