import { observer } from "mobx-react-lite";

import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import ModalSidebar from "@repo/ui/ModalSidebar";
import Tabs from "@repo/ui/Tabs";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";

import ProfileEditTab from "./ProfileEditTab";
import { ProfileEditTabKey, profileEditTabs } from "./config";

import * as s from "./ModalProfileEdit.module.scss";

const ModalProfileEdit: React.FC = () => {
  const { isPhone } = useWidth();

  const modalState = useModalsStoreOpenState(ModalsBaseKeys.PROFILE_EDIT);

  return (
    <ModalSidebar
      isMobile={isPhone}
      isOpened={modalState.opened}
      title="Редактирование"
      onClose={modalState.close}
    >
      <Tabs className={s["modal-profile__tabs"]} tabs={profileEditTabs}>
        {({ selectedTabKey }) => (
          <Tabs.Content
            selectedTabKey={selectedTabKey}
            tabKey={ProfileEditTabKey.PERSONAL_INFORMATION}
          >
            <ProfileEditTab onModalClose={modalState.close} />
          </Tabs.Content>
        )}
      </Tabs>
    </ModalSidebar>
  );
};

export default observer(ModalProfileEdit);
