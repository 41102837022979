import { useMemo } from "react";

import type { EmployeeType, EmployeeTypeValue } from "@repo/types/employee";
import type { OptionWithKey } from "@repo/types/option";
import type { UserType, UserTypeValue } from "@repo/types/user";

import { DetailMap } from "./config";

export function useDetails<
  T extends UserType | EmployeeType,
  FT extends UserTypeValue | EmployeeTypeValue,
>(data: T | null): OptionWithKey[] {
  return useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.entries(DetailMap).reduce<OptionWithKey[]>(
      (acc, [key, item]) => {
        const field: FT = data[key];

        // Некоторые поля могут не прийти с бэка, их может убрать администратор.
        // Или их может не быть в переданных данных.
        if (field === undefined) {
          return acc;
        }

        const detail = {
          key: item.key,
          label: item.label,
          value: "-",
        };

        if (field) {
          detail.value = item.formatFn?.(field) || String(field);
        }

        acc.push(detail);
        return acc;
      },
      [],
    );
  }, [data]);
}
