import PopoverBase from "./Popover";
import PopoverClose from "./components/PopoverClose";
import PopoverContent from "./components/PopoverContent";
import PopoverTrigger from "./components/PopoverTrigger";

const Popover = Object.assign(PopoverBase, {
  Base: PopoverBase,
  Close: PopoverClose,
  Content: PopoverContent,
  Trigger: PopoverTrigger,
});

export default Popover;

export type { PopoverProps } from "./Popover";
export { TRANSITION_VARIANT } from "./config";
