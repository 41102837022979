import type { TreeData } from "@repo/types/tree";

export const findTreePath = (
  tree: TreeData[],
  targetKey: string,
): string[] | null => {
  for (const node of tree) {
    if (node.key === targetKey) {
      return [node.key];
    }

    if (node.children) {
      const path = findTreePath(node.children, targetKey);

      if (path) {
        return [node.key, ...path];
      }
    }
  }

  return null;
};
