import { createContextLocalStore } from "../../createContextLocalStore";

import FilterOrgStructureStore from "./FilterOrgStructureStore";

const { Provider, useStore } = createContextLocalStore(FilterOrgStructureStore);

export {
  Provider as FilterOrgStructureStoreProvider,
  useStore as useFilterOrgStructureStore,
  FilterOrgStructureStore,
};
