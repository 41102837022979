import clsx from "clsx";
import { memo, useCallback, useState } from "react";

import { InputSearch, InputSearchSize, InputSearchTheme } from "@repo/ui/Input";
import { Switch, SwitchColor, SwitchSize } from "@repo/ui/Switch";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import HeaderSticky from "../HeaderSticky";

import * as s from "./HeaderEmployee.module.scss";

export type HeaderEmployeeProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const HeaderEmployee: React.FC<HeaderEmployeeProps> = ({
  className,
}: HeaderEmployeeProps) => {
  const { isPhone } = useWidth();
  const [searchValue, setSearchValue] = useState<string>("");
  const [toggleValue, setToggleValue] = useState<boolean>(false);

  const handleSearch = useCallback(() => {}, []);

  return (
    <div className={clsx(s["header-employee"], className)}>
      <HeaderSticky />

      <div className={s["header-employee__search"]}>
        <InputSearch
          placeholder={
            isPhone
              ? "Искать сотрудника"
              : "ФИО, должность, табельный номер, телефон, почта"
          }
          size={InputSearchSize.MEDIUM}
          theme={InputSearchTheme.SECONDARY}
          value={searchValue}
          onChange={setSearchValue}
          onSearch={handleSearch}
        />

        <div className={s["header-employee__switch"]}>
          <Typography
            Component="p"
            typographyType={
              isPhone ? TypographyType.BODY_SM : TypographyType.BODY_LG
            }
            typographyWeight={TypographyWeight._500}
          >
            Показать уволенных
          </Typography>

          <Switch
            checked={toggleValue}
            color={SwitchColor.PRIMARY}
            label="Показать уволенных"
            size={SwitchSize.M}
            onChange={(e) => setToggleValue(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderEmployee);
