import clsx from "clsx";
import { memo, useState } from "react";

import { useLocalStore } from "@repo/data/hooks";
import {
  AvatarChangeStore,
  AvatarChangeStoreProvider,
} from "@repo/data/store/local/AvatarChangeStore";

import ProfileEditAvatar from "./components/ProfileEditAvatar";
import ProfileEditForm from "./components/ProfileEditForm";
import { ProfileEditTabStage } from "./config";

import * as s from "./ProfileEditTab.module.scss";

export type ProfileEditTabProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Функция, вызываемая при закрытии модального окна. */
  onModalClose: () => void;
};

const ProfileEditTab: React.FC<ProfileEditTabProps> = ({
  className,
  onModalClose,
}: ProfileEditTabProps) => {
  const avatarStore = useLocalStore(() => new AvatarChangeStore());

  const [stage, setStage] = useState<ProfileEditTabStage>(
    ProfileEditTabStage.FORM,
  );

  return (
    <AvatarChangeStoreProvider store={avatarStore}>
      <div className={clsx(s["profile-edit"], className)}>
        {stage === ProfileEditTabStage.FORM && (
          <ProfileEditForm
            onModalClose={onModalClose}
            onStageChange={setStage}
          />
        )}

        {stage === ProfileEditTabStage.AVATAR && (
          <ProfileEditAvatar onStageChange={setStage} />
        )}
      </div>
    </AvatarChangeStoreProvider>
  );
};

export default memo(ProfileEditTab);
