import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./FormDisclaimer.module.scss";

export type FormDisclaimerProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const FormDisclaimer: React.FC<PropsWithChildren<FormDisclaimerProps>> = ({
  className,
  children,
}: PropsWithChildren<FormDisclaimerProps>) => {
  return (
    <Typography
      Component="p"
      className={clsx(s["form-disclaimer"], className)}
      typographyType={TypographyType.BODY_SM}
      typographyWeight={TypographyWeight._500}
    >
      {children}
    </Typography>
  );
};

export default memo(FormDisclaimer);
