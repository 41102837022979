import clsx from "clsx";
import { memo, type PropsWithChildren, useCallback } from "react";

import { IconDirection, IconSize } from "./config";

import * as s from "./IconBase.module.scss";

export type IconCommonProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Размер иконки. */
  size?: IconSize | null;
  /** Направление иконки. */
  direction?: IconDirection;
  /** Если true, иконка будет в состоянии disabled. */
  disabled?: boolean;
  /** Обработчик события клика. */
  onClick?: (e: React.MouseEvent) => void;
};

export type IconBaseProps = IconCommonProps & React.SVGAttributes<SVGElement>;

const IconBase: React.FC<PropsWithChildren<IconBaseProps>> = ({
  className,
  size = IconSize.M,
  direction,
  disabled,
  onClick,
  children,
  ...props
}: PropsWithChildren<IconBaseProps>) => {
  const classNames = clsx(
    s["icon"],
    size && s[`icon_size-${size}`],
    direction && s[`icon_direction-${direction}`],
    !disabled && onClick && s["icon_button"],
    className,
  );

  const handleClick = useCallback<React.MouseEventHandler<SVGSVGElement>>(
    (e) => {
      if (disabled || !onClick) {
        return;
      }

      onClick(e);
    },
    [disabled, onClick],
  );

  return (
    <svg
      className={classNames}
      fill="none"
      height="24"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      {...props}
    >
      {children}
    </svg>
  );
};

export default memo(IconBase);
