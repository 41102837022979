import { memo } from "react";

import type { OptionWithKey } from "@repo/types/option";
import Tag, { TagColor, TagSize } from "@repo/ui/Tag";

import * as s from "./OptionLabel.module.scss";

export type OptionLabelProps = {
  option: OptionWithKey;
};

const OptionLabel: React.FC<OptionLabelProps> = ({
  option,
}: OptionLabelProps) => {
  return (
    <Tag
      className={s["option-view"]}
      color={TagColor.DEFAULT}
      label={option.label}
      size={TagSize.M}
      style={{ backgroundColor: option.value }}
    />
  );
};

export default memo(OptionLabel);
