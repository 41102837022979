import clsx from "clsx";
import { observer } from "mobx-react-lite";

import GlobalSettingsStore from "@repo/data/store/single/GlobalSettingsStore";
import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import Logo from "@repo/ui/Logo";

import Menu from "../Menu";

import * as s from "./Sidebar.module.scss";

export type SidebarProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const Sidebar: React.FC<SidebarProps> = ({ className }: SidebarProps) => {
  const settingsStore = GlobalSettingsStore.getInstance();

  return (
    <section className={clsx(s["sidebar"], className)}>
      <div className={s["sidebar__header"]}>
        <Logo
          subtitle={settingsStore.data.companyName}
          title={settingsStore.data.systemName}
        />

        <Button.Text className={s["sidebar__button"]}>
          <Icon size={IconSize.M} type={IconType.CLOSE_MENU} />
        </Button.Text>
      </div>

      <div className={s["sidebar__menu-container"]}>
        <Menu className={s["sidebar__menu"]} />
      </div>
    </section>
  );
};

export default observer(Sidebar);
