import type { MaskitoOptions } from "@maskito/core";
import { action, makeObservable, observable } from "mobx";

import AFormField from "../AFormField";
import type { FieldModelOptions } from "../AFormField";

type PrivateFields = "_mask";

class FormFieldMask<V extends string = string> extends AFormField<V> {
  private readonly _initMask: MaskitoOptions;

  private _mask: MaskitoOptions;

  constructor(mask: MaskitoOptions, options: FieldModelOptions<V>) {
    super({ ...options });

    this._initMask = mask;
    this._mask = mask;

    makeObservable<this, PrivateFields>(this, {
      _mask: observable.ref,
      setMask: action.bound,
    });
  }

  get mask(): MaskitoOptions {
    return this._mask;
  }

  protected _prepareValue(value: V): V {
    return value;
  }

  setMask(mask: MaskitoOptions): void {
    this._mask = mask;
  }

  reset(): void {
    this._mask = this._initMask;

    super.reset();
  }

  destroy(): void {
    super.destroy();
  }
}

export default FormFieldMask;
