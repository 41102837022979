import type { OptionWithKey } from "@repo/types/option";

/**
 * Проверяет наличие значения в массиве и:
 * - если значение уже есть, оно будет удалено из массива.
 * - если значение отсутствует, оно будет добавлено в конец массива.
 *
 * @param {O} item - Текущее значение.
 * @param {O[]} list - Исходный массив.
 *
 * @returns {O[]} Обновленный массив.
 */
export function toggleValueInArray<O extends OptionWithKey = OptionWithKey>(
  item: O,
  list: O[],
): O[] {
  const hasItem = list.some(({ key }) => key === item.key);

  return hasItem ? list.filter(({ key }) => key !== item.key) : [...list, item];
}
