import { createContextLocalStore } from "../createContextLocalStore";

import ProfileEditStore from "./ProfileEditStore";

const { Provider, useStore } = createContextLocalStore(ProfileEditStore);

export {
  Provider as ProfileEditStoreProvider,
  useStore as useProfileEditStore,
  ProfileEditStore,
};

export { ProfileEditField } from "./config";
