import { createContext, useContext } from "react";

import type { ILocalStore } from "@repo/data/store/interface";

export const createContextLocalStore = <T extends ILocalStore>(
  Constructor: new (...args: any[]) => T,
) => {
  const Context = createContext<T | null>(null);

  const Provider = ({
    children,
    store,
  }: React.PropsWithChildren<{ store: T }>) => (
    <Context.Provider value={store}>{children}</Context.Provider>
  );

  const useStore = () => {
    const context = useContext(Context);

    if (!context) {
      throw new Error(
        `${Constructor.name} must be used within a Context.Provider`,
      );
    }

    return context;
  };

  return {
    Provider,
    useStore,
  };
};
