import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import type { EmployeeCard } from "@repo/types/employee";
import { IconType } from "@repo/types/icon";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import Icon, { IconSize } from "@repo/ui/Icon";
import { LabelList } from "@repo/ui/Label";
import Tag, { TagAngle, TagColor, TagSize } from "@repo/ui/Tag";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";
import { formatPhoneNumber } from "@repo/utils/format";

import CardDetail from "./CardDetail";
import CardLink from "./CardLink";

import * as s from "./CardProfile.module.scss";

export type CardProfileProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Информация о сотруднике. */
  employee: EmployeeCard;
  /** Показывать ли границу у карточки. */
  withBorder?: boolean;
  /** Мобильная версия. */
  isMobile: boolean;
};

const CardProfile: React.FC<PropsWithChildren<CardProfileProps>> = ({
  className,
  employee,
  withBorder = false,
  isMobile,
  children,
}: PropsWithChildren<CardProfileProps>) => {
  return (
    <div
      className={clsx(
        s["card-profile"],
        withBorder && s["card-profile_with-border"],
        className,
      )}
    >
      <CardLink employee={employee}>
        <AvatarImage
          round
          alt={employee.fullName ?? "Аватар сотрудника."}
          size={AvatarSize.S}
          src={employee.avatar}
        />
      </CardLink>

      <div
        className={clsx(
          s["card-profile__content"],
          employee.isFired && s["card-profile__content_fired"],
        )}
      >
        {employee.isManager && (
          <Tag
            angle={TagAngle.BOTTOM_LEFT}
            className={s["card-profile__tag"]}
            color={TagColor.DEFAULT}
            label="Руководитель"
            size={TagSize.XXS}
          />
        )}

        <div className={s["card-profile__header"]}>
          <div className={s["card-profile__title"]}>
            <CardLink employee={employee}>
              <Typography
                Component="p"
                className={s["card-profile__name"]}
                typographyType={
                  isMobile ? TypographyType.BODY_SM : TypographyType.BODY_LG
                }
                typographyWeight={TypographyWeight._700}
              >
                {employee.fullName}
              </Typography>
            </CardLink>

            <Typography
              Component="p"
              className={s["card-profile__position"]}
              typographyType={
                isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
              }
              typographyWeight={TypographyWeight._500}
            >
              {employee.employeePosition ?? employee.userPosition}
            </Typography>
          </div>

          {employee.labels && (
            <LabelList
              angle={TagAngle.BOTTOM_LEFT}
              className={s["card-profile__labels"]}
              labels={employee.labels}
              size={TagSize.S}
            />
          )}
        </div>

        <div className={s["card-profile__details"]}>
          <CardDetail isMobile={isMobile} title="Электронная почта">
            {employee.email ? employee.email : "-"}
          </CardDetail>
          <CardDetail isMobile={isMobile} title="Телефон">
            {employee.phone ? formatPhoneNumber(employee.phone) : "-"}
          </CardDetail>
          <CardDetail isMobile={isMobile} title="Табельный номер">
            {employee.employeeNumber ?? employee.userId}
          </CardDetail>
        </div>

        <div className={s["card-profile__footer"]}>{children}</div>
      </div>

      <CardLink className={s["card-profile__link"]} employee={employee}>
        <Icon size={IconSize.M} type={IconType.EXTEND} />
      </CardLink>
    </div>
  );
};

export default memo(CardProfile);
