import clsx from "clsx";
import { memo } from "react";

import type { OrgStructureFlat } from "@repo/types/orgStructure";

import { ORG_STRUCTURE_DATA } from "../mocks/__mocks__";

import OrgStructureLink from "./OrgStructureLink";

import * as s from "./OrgStructureList.module.scss";

export type OrgStructureListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Структура организации. */
  orgStructure?: OrgStructureFlat;
};

const OrgStructureList: React.FC<OrgStructureListProps> = ({
  className,
}: OrgStructureListProps) => {
  return (
    <div className={clsx(s["list"], className)}>
      {ORG_STRUCTURE_DATA.map((item) => (
        <OrgStructureLink key={item.depId} orgStructure={item} />
      ))}
    </div>
  );
};

export default memo(OrgStructureList);
