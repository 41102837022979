import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useCallback, useMemo, useState } from "react";

import { IconType } from "@repo/types/icon";
import type { TreeData } from "@repo/types/tree";
import Button from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { BASE_INDENT } from "../config";

import * as s from "./TreeNode.module.scss";

export type TreeNodeProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Данные для построения дерева. */
  data: TreeData;
  /** Отступ от левого края. */
  indent: number;
  /** Путь к первоначально выбранному элементу дерева. */
  initialSelectedPath: string[] | null;
  /** Мобильная версия. */
  isMobile: boolean;
  /** Родительский элемент дерева. */
  parentNode?: TreeData | null;
  /** Выбранный элемент дерева. */
  selectedKey: string | null;
  /** Функция, вызывается при выборе элемента дерева. */
  onSelect: (node: TreeData, parentNode: TreeData | null) => void;
};

const TreeNode: React.FC<TreeNodeProps> = ({
  className,
  data,
  indent,
  initialSelectedPath,
  isMobile,
  parentNode = null,
  selectedKey,
  onSelect,
}: TreeNodeProps) => {
  const [opened, setOpened] = useState<boolean>(
    () => initialSelectedPath?.includes(data.key) || false,
  );

  const isSelected = useMemo(
    () => selectedKey && selectedKey === data.key,
    [selectedKey, data.key],
  );

  const hasSubtree = useMemo(
    () => data.children && data.children.length > 0,
    [data.children],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      setOpened((prevState) => !prevState);
    },
    [],
  );

  const handleSelect = useCallback(() => {
    onSelect(data, parentNode);
  }, [data, parentNode, onSelect]);

  return (
    <div className={clsx(s["node"], className)}>
      <Button className={s["node__header"]} onClick={handleSelect}>
        <div
          className={s["node__offset"]}
          style={{ marginLeft: `${indent}px` }}
        >
          <Button
            className={clsx(
              s["node__button"],
              isSelected && s["node__button_selected"],
              !hasSubtree && s["node__button_dot"],
            )}
            onClick={handleClick}
          >
            {hasSubtree ? (
              <Icon
                size={IconSize.M}
                type={opened ? IconType.MINUS : IconType.PLUS}
              />
            ) : (
              <Icon size={IconSize.M} type={IconType.DOT} />
            )}
          </Button>

          <Typography
            Component="p"
            className={clsx(
              s["node__title"],
              isSelected && s["node__title_selected"],
            )}
            typographyType={
              isMobile ? TypographyType.BODY_SM : TypographyType.BODY_MD
            }
            typographyWeight={TypographyWeight._500}
          >
            {data.label}

            {data.counter && (
              <>
                {" "}
                <span className={s["node__counter"]}>{data.counter}</span>
              </>
            )}
          </Typography>
        </div>
      </Button>

      {data.children && (
        <motion.div
          animate={{ opacity: opened ? 1 : 0, height: opened ? "auto" : 0 }}
          className={s["node__children"]}
          exit={{ opacity: 0, height: 0 }}
          initial={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {data.children.map((leaf) => (
            <TreeNode
              key={leaf.key}
              data={leaf}
              indent={indent + BASE_INDENT}
              initialSelectedPath={initialSelectedPath}
              isMobile={isMobile}
              parentNode={data}
              selectedKey={selectedKey}
              onSelect={onSelect}
            />
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default memo(TreeNode);
