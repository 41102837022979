import { makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { RuleKey } from "@repo/data/config";
import InterfaceRuleModel, {
  normalizeRule,
} from "@repo/data/store/models/InterfaceRuleModel";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";

type PrivateFields = "_rules";

export class InterfaceRuleStore {
  private readonly _meta = new MetaStore();

  private _rules: Map<RuleKey, InterfaceRuleModel> = new Map();

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _rules: observable.shallow,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  getInterfaceRuleByKey(key: RuleKey): InterfaceRuleModel | null {
    return this._rules.get(key) ?? null;
  }

  async fetchRules(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.INTERFACE_RULE_LIST,
      "GET",
    );

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._rules = data.items.reduce((acc, rule) => {
        acc.set(
          rule.key as RuleKey,
          new InterfaceRuleModel(rule.key as RuleKey, normalizeRule(rule)),
        );
        return acc;
      }, new Map());

      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default InterfaceRuleStore;
