import {
  type FormFieldNumber,
  isFormModelNumber,
} from "@repo/data/store/models/FormModel";
import { InputBase, type InputBaseProps } from "@repo/ui/Input";
import WithError from "@repo/ui/WithError";

import { withField } from "../../hoc";

export type FieldNumberProps = {
  /** Имя поля формы. */
  name: string;
  /** Модель поля формы. */
  field: FormFieldNumber;
} & InputBaseProps;

const BaseFieldNumber: React.FC<FieldNumberProps> = ({
  className,
  field,
  ...props
}: FieldNumberProps) => {
  return (
    <WithError className={className} message={field.errorMessage}>
      <InputBase
        {...props}
        disabled={field.disable}
        error={field.hasError}
        name={field.name}
        value={field.value}
        onBlur={field.setTouched}
        onChange={field.setValue}
      />
    </WithError>
  );
};

export const FieldNumber = withField(BaseFieldNumber, isFormModelNumber);
