import { memo, PropsWithChildren } from "react";

import Modal, { type ModalProps } from "@repo/ui/Modal";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import * as s from "./ModalSidebar.module.scss";

export type ModalSidebarProps = {
  /** Заголовок боковой панели. */
  title: string;
  /** Слот для кнопок управления. */
  controlsSlot?: React.ReactNode;
} & ModalProps;

const ModalSidebar: React.FC<PropsWithChildren<ModalSidebarProps>> = ({
  title,
  controlsSlot,
  isOpened,
  isMobile,
  onClose,
  children,
  ...props
}: PropsWithChildren<ModalSidebarProps>) => {
  return (
    <Modal
      className={s["modal-sidebar"]}
      isMobile={isMobile}
      isOpened={isOpened}
      onClose={onClose}
      {...props}
    >
      <Modal.Content className={s["modal-sidebar__content"]}>
        <Typography
          Component="p"
          typographyType={TypographyType.H3}
          typographyWeight={TypographyWeight._700}
        >
          {title}
        </Typography>

        {children}

        {controlsSlot && (
          <div className={s["modal-sidebar__controls"]}>{controlsSlot}</div>
        )}

        {!isMobile && (
          <Modal.Close
            className={s["modal-sidebar__close"]}
            onClick={onClose}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default memo(ModalSidebar);
