import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import * as s from "./ModalContent.module.scss";

export type ModalContentProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const ModalContent: React.FC<PropsWithChildren<ModalContentProps>> = ({
  className,
  children,
}: PropsWithChildren<ModalContentProps>) => {
  return <div className={clsx(s["modal-content"], className)}>{children}</div>;
};

export default memo(ModalContent);
