import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { useEmployeeStore } from "@repo/data/store/local/EmployeeStore";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import { LabelList } from "@repo/ui/Label";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import DetailList, { useDetails } from "../../shared/DetailList";

import * as s from "./PersonalData.module.scss";

export type PersonalDataProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const PersonalData: React.FC<PersonalDataProps> = ({
  className,
}: PersonalDataProps) => {
  const { isPhone } = useWidth();

  const store = useEmployeeStore();
  const details = useDetails(store.employee);

  if (!store.employee) {
    return null;
  }

  return (
    <div className={clsx(s["personal"], className)}>
      <div className={s["personal__avatar"]}>
        <AvatarImage
          alt={store.employee.fullName || "Аватар сотрудника"}
          className={s["personal__avatar-img"]}
          round={!isPhone}
          size={AvatarSize.UNSET}
          src={store.employee.avatar}
        />
      </div>

      <div className={s["personal__content"]}>
        <LabelList labels={store.employee.labels} />

        <Typography
          Component="h1"
          typographyType={TypographyType.H1}
          typographyWeight={TypographyWeight._700}
        >
          {store.employee.fullName}
        </Typography>

        <DetailList details={details} />
      </div>
    </div>
  );
};

export default observer(PersonalData);
