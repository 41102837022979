import clsx from "clsx";
import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";
import { pluralizeTemplate } from "@repo/utils/format";

import AvatarImage from "../AvatarImage";
import { AvatarSize } from "../config";

import type { AvatarEmployeeData } from "./types";

import * as s from "./AvatarEmployeeGroup.module.scss";

export type AvatarEmployeeGroupProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Аватары сотрудников. */
  avatars: AvatarEmployeeData;
  /** Количество сотрудников. */
  totalEmployees: number;
};

const AvatarEmployeeGroup: React.FC<AvatarEmployeeGroupProps> = ({
  className,
  avatars,
  totalEmployees,
}: AvatarEmployeeGroupProps) => {
  return (
    <div className={clsx(s["avatar-group"], className)}>
      <div className={s["avatar-group__list"]}>
        {avatars.map((avatar) => (
          <AvatarImage
            key={avatar.id}
            border
            round
            alt={avatar.alt}
            className={s["avatar-group__item"]}
            size={AvatarSize.XS}
            src={avatar.src}
          />
        ))}
      </div>

      <Typography
        Component="p"
        className={s["avatar-group__text"]}
        typographyType={TypographyType.BODY_SM}
        typographyWeight={TypographyWeight._500}
      >{`и еще ${pluralizeTemplate(totalEmployees, "сотрудник", "сотрудника", "сотрудников")}`}</Typography>
    </div>
  );
};

export default memo(AvatarEmployeeGroup);
