import clsx from "clsx";
import { memo } from "react";

import type { OptionWithKey } from "@repo/types/option";
import Popover from "@repo/ui/Popover";

import OptionLabel from "./OptionLabel";
import SelectDropdown from "./SelectDropdown";
import SelectTrigger from "./SelectTrigger";
import { middleware } from "./config";
import { formatValueDefault } from "./utils";

import * as s from "./Select.module.scss";

export type SelectProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Плейсхолдер. */
  placeholder?: string;
  /** Функция форматирования значения опции. */
  formatValue?: (options: OptionWithKey[]) => string | React.ReactNode;
  /** Компонент для отображения опции. */
  ComponentOption?: React.ComponentType<{ option: OptionWithKey }> | null;
  /** Список опций. */
  options: OptionWithKey[];
  /** Выбранные опции. */
  selectedOptions: OptionWithKey[];
  /** Обработчик потери фокуса. */
  onBlur?: () => void;
  /** Callback, вызываемый при выборе опции. */
  onChange: (options: OptionWithKey[]) => void;
};

const Select: React.FC<SelectProps> = ({
  className,
  placeholder = "Выберите опцию",
  formatValue = formatValueDefault,
  ComponentOption = OptionLabel,
  options,
  selectedOptions = [],
  onBlur,
  onChange,
}: SelectProps) => {
  return (
    <div className={clsx(s["select"], className)}>
      <Popover modal middleware={middleware}>
        <Popover.Trigger>
          {(props) => (
            <SelectTrigger
              ComponentOption={ComponentOption}
              formatValue={formatValue}
              placeholder={placeholder}
              selectedOptions={selectedOptions}
              onBlur={onBlur}
              {...props}
            />
          )}
        </Popover.Trigger>

        <Popover.Content>
          <SelectDropdown
            options={options}
            selectedOptions={selectedOptions}
            onChange={onChange}
          >
            {({ option }) =>
              ComponentOption ? (
                <ComponentOption option={option} />
              ) : (
                formatValue([option])
              )
            }
          </SelectDropdown>
        </Popover.Content>
      </Popover>
    </div>
  );
};

export default memo(Select);
