import { memo, useMemo } from "react";

import { type AvatarEmployeeData, AvatarEmployeeGroup } from "@repo/ui/Avatar";

import CardProfile, { CardDetail, type CardProfileProps } from "../CardProfile";

export type CardManagerProps = CardProfileProps;

const CardManager: React.FC<CardManagerProps> = ({
  className,
  employee,
  withBorder = false,
  isMobile,
}: CardManagerProps) => {
  const subordinates = useMemo<AvatarEmployeeData | null>(
    () =>
      employee.subordinates && employee.subordinates.length > 0
        ? employee.subordinates.map((subordinate) => ({
            id: String(subordinate.employeeNumber),
            src: subordinate.avatar,
            alt: subordinate.fullName ?? "Аватар сотрудника",
          }))
        : null,
    [employee.subordinates],
  );

  return (
    <CardProfile
      className={className}
      employee={employee}
      isMobile={isMobile}
      withBorder={withBorder}
    >
      {subordinates && (
        <CardDetail isMobile={isMobile} title="Подчиненные">
          <AvatarEmployeeGroup
            avatars={subordinates}
            totalEmployees={subordinates.length}
          />
        </CardDetail>
      )}
    </CardProfile>
  );
};

export default memo(CardManager);
