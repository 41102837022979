import type { MiddlewareParams } from "../../apiWithChecks/types";
import {
  fetchApi,
  isNetworkErrorResponse,
  isNetworkOkResponse,
} from "../../fetchApi";
import type { ApiOrgStructureResponse } from "../types";

async function callFetchApi(
  params: MiddlewareParams,
): Promise<ApiOrgStructureResponse> {
  const fetch = await fetchApi(params.path, params.method, params.options);

  if (!fetch.response) {
    return {
      isError: true,
      data: null,
      errorData: null,
      rawResponse: null,
    };
  }

  const { response } = fetch;

  /**
   * Положительный HTTP ответ
   */
  if (isNetworkOkResponse(response)) {
    return {
      isError: false,
      data: response.data,
      errorData: null,
      rawResponse: response,
    };
  }

  /**
   * HTTP ответ с ошибкой
   */
  if (isNetworkErrorResponse(response)) {
    return {
      isError: true,
      data: null,
      errorData: null,
      rawResponse: response,
    };
  }

  /**
   * Произошло что-то совсем неизвестное
   */
  return {
    isError: true,
    data: null,
    errorData: null,
    rawResponse: response,
  };
}

export default callFetchApi;
