import { computed, makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import type { ILocalStore } from "@repo/data/store/interface";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { LabelType } from "@repo/types/label";
import type { OptionWithKey } from "@repo/types/option";

import { normalizeLabels } from "./normalize";

type PrivateFields = "_labels";

class LabelStore implements ILocalStore {
  private readonly _meta = new MetaStore();

  private _labels: LabelType[] = [];

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _labels: observable.ref,

      labelOptions: computed,
    });

    this._fetch();
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get hasLabels(): boolean {
    return this._labels.length > 0;
  }

  get labels(): LabelType[] {
    return this._labels;
  }

  get labelOptions(): OptionWithKey[] {
    return this._labels.map((label) => ({
      key: label.id,
      label: label.title,
      value: label.color,
    }));
  }

  async _fetch(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(ApiPath.LABEL_LIST, "GET");

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this._labels = normalizeLabels(data.items);
      this._meta.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    this._meta.destroy();
  }
}

export default LabelStore;
