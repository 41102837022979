import { action, computed, makeObservable, observable } from "mobx";

import type { ILocalStore } from "@repo/data/store/interface";
import GlobalOrgStructureStore, {
  OrgStructureStore,
} from "@repo/data/store/single/GlobalOrgStructureStore";
import {
  buildOrgStructureTree,
  filterOrgStructureTree,
} from "@repo/data/utils";
import type { TreeData } from "@repo/types/tree";

type PrivateFields = "_search";

class ModalOrgStructureStore implements ILocalStore {
  private readonly _orgStructureStore: OrgStructureStore;

  private _search = "";

  constructor() {
    this._orgStructureStore = GlobalOrgStructureStore.getInstance();

    makeObservable<this, PrivateFields>(this, {
      _search: observable,
      orgStructureTree: computed,
      hasSearchResult: computed,
      setSearch: action.bound,
    });
  }

  get isLoading(): boolean {
    return this._orgStructureStore.meta.isLoading;
  }

  get orgStructureTree(): TreeData[] {
    return this._search
      ? filterOrgStructureTree(
          this._orgStructureStore.orgStructureFlat,
          this._search,
        )
      : buildOrgStructureTree(this._orgStructureStore.orgStructureFlat);
  }

  get hasSearchResult(): boolean {
    return Boolean(this._search) && this.orgStructureTree.length !== 0;
  }

  get search(): string {
    return this._search;
  }

  setSearch(value: string) {
    this._search = value;
  }

  destroy(): void {
    // do nothing
  }
}

export default ModalOrgStructureStore;
