import clsx from "clsx";
import { memo, useEffect, useRef } from "react";

import { Input, type InputCommonProps } from "@repo/ui/Input";

import * as s from "./Textarea.module.scss";

type TextareaBaseProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  keyof InputCommonProps
>;

export type TextareaProps = InputCommonProps & {
  /** Если true, текстовое поле будет автоматически увеличиваться по высоте. */
  autoGrow?: boolean;
  /** Минимальное количество строк отображаемых в текстовом поле. */
  minRows?: number;
} & TextareaBaseProps;

const Textarea: React.FC<TextareaProps> = ({
  className,
  autoGrow = true,
  minRows = 1,
  value,
  ...props
}: TextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea && autoGrow) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [autoGrow, value]);

  return (
    <Input
      ref={textareaRef}
      className={clsx(s["textarea"], className)}
      rows={minRows}
      value={value}
      {...props}
    >
      {({ ref, className, handleChange, ...props }) => (
        <textarea
          ref={ref as React.RefObject<HTMLTextAreaElement>}
          className={clsx(s["textarea__field"], className)}
          onChange={handleChange}
          {...props}
        />
      )}
    </Input>
  );
};

export default memo(Textarea);
