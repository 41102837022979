import { action, makeObservable, observable, set } from "mobx";

import type { UserType } from "@repo/types/user";

type PrivateFields = "_data";

class UserStore {
  private _data: UserType;

  constructor(data: UserType) {
    this._data = data;

    makeObservable<this, PrivateFields>(this, {
      _data: observable,

      setData: action,
      setAvatar: action,
    });
  }

  get data(): UserType {
    return this._data;
  }

  setData(data: UserType): void {
    this._data = data;
  }

  setAvatar(avatar: string | null): void {
    set(this._data, { avatar });
  }
}

export default UserStore;
