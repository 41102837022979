export enum TagAngle {
  /** Верхний левый угол */
  TOP_LEFT = "top-left",
  /** Верхний правый угол */
  TOP_RIGHT = "top-right",
  /** Нижний левый угол */
  BOTTOM_LEFT = "bottom-left",
  /** Нижний правый угол */
  BOTTOM_RIGHT = "bottom-right",
}

export enum TagSize {
  /** height: 22px/22px */
  XXS = "xxs",
  /** height: 24px/28px */
  XS = "xs",
  /** height: 30px/30px */
  S = "s",
  /** height: 28px/36px */
  M = "m",
  /** height: 32px/40px */
  L = "l",
}

export enum TagColor {
  /**
   * Default
   * bc: transparent, color: white
   * Используется по умолчанию, когда цвет возвращается от backend
   */
  DEFAULT = "default",
  /**
   * Primary
   * bc: pink, color: white
   */
  PRIMARY = "primary",
  /**
   * Gray
   * bc: gray, color: black
   */
  GRAY = "gray",
}
