import clsx from "clsx";
import { memo, useCallback } from "react";

import type { OptionWithKey } from "@repo/types/option";
import { toggleValueInArray } from "@repo/utils/array";

import SelectOption from "../SelectOption";

import * as s from "./SelectDropdown.module.scss";

export type SelectDropdownProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Список возможных опций. */
  options: OptionWithKey[];
  /** Выбранные опции. */
  selectedOptions: OptionWithKey[];
  /** Callback, вызываемый при выборе опции. */
  onChange: (options: OptionWithKey[]) => void;
  /** Компонент для отображения опции. */
  children: (props: { option: OptionWithKey }) => React.ReactNode;
};

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  className,
  options,
  selectedOptions,
  onChange,
  children,
}: SelectDropdownProps) => {
  const isSelectedOption = useCallback(
    (option: OptionWithKey) =>
      selectedOptions.some((item) => item.key === option.key),
    [selectedOptions],
  );

  const handleChange = useCallback(
    (option: OptionWithKey) => {
      const updatedItems = toggleValueInArray(option, selectedOptions);

      onChange(updatedItems);
    },
    [selectedOptions, onChange],
  );

  return (
    <ul className={clsx(s["dropdown"], className)}>
      <li className={s["dropdown__item"]}>
        {options.map((option: OptionWithKey) => (
          <SelectOption
            key={option.key}
            isChecked={isSelectedOption(option)}
            option={option}
            onChange={handleChange}
          >
            {children({ option })}
          </SelectOption>
        ))}
      </li>
    </ul>
  );
};

export default memo(SelectDropdown);
