// TODO
// Избавить пакет ui от зависимостей @repo/data и mobx-react-lite.
// Зависимости возникают из-за компонента Form.

import FormBase from "./Form";
import { FieldMask } from "./components/FieldMask";
import { FieldNumber } from "./components/FieldNumber";
import { FieldPassword } from "./components/FieldPassword";
import { FieldSelect } from "./components/FieldSelect";
import { FieldText } from "./components/FieldText";
import { FieldTextarea } from "./components/FieldTextarea";
import FormDisclaimer from "./components/FormDisclaimer";
import FormFieldset from "./components/FormFieldset";
import FormSubmit from "./components/FormSubmit";

const Form = Object.assign(FormBase, {
  Base: FormBase,
  Mask: FieldMask,
  Number: FieldNumber,
  Password: FieldPassword,
  Select: FieldSelect,
  Text: FieldText,
  Textarea: FieldTextarea,
  Disclaimer: FormDisclaimer,
  Fieldset: FormFieldset,
  Submit: FormSubmit,
});

export default Form;

export type { FormProps } from "./Form";
export type { FieldMaskProps } from "./components/FieldMask";
export type { FieldNumberProps } from "./components/FieldNumber";
export type { FieldPasswordProps } from "./components/FieldPassword";
export type { FieldSelectProps } from "./components/FieldSelect";
export type { FieldTextProps } from "./components/FieldText";
export type { FieldTextareaProps } from "./components/FieldTextarea";
export type { FormDisclaimerProps } from "./components/FormDisclaimer";
export type { FormFieldsetProps } from "./components/FormFieldset";
export type { FormSubmitProps } from "./components/FormSubmit";

export { useFormContext } from "./context";
