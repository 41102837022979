import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import type { EmployeeCard } from "@repo/types/employee";
import { Card, CardEmployee, CardManager } from "@repo/ui/Card";

import useWidth from "@app/hooks/useWidth";
import { useContentContext } from "@app/pages/auth/Layout";

import { EMPLOYEE_DATA, EMPLOYEE_MANAGER_DATA } from "../mocks/__mocks__";

import * as s from "./EmployeeList.module.scss";

export type EmployeeListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const EmployeeList: React.FC<EmployeeListProps> = ({
  className,
}: EmployeeListProps) => {
  const { isPhone } = useWidth();

  const containerRef = useContentContext();

  const [employees, setEmployees] = useState<EmployeeCard[]>(() =>
    EMPLOYEE_DATA.slice(0, 3),
  );

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      setEmployees((prevState) => [
        ...prevState,
        ...EMPLOYEE_DATA.slice(prevState.length, prevState.length + 3),
      ]);
    }, 500);
  }, []);

  const itemContent = useCallback(
    (index: number, employee: EmployeeCard) => (
      <CardEmployee
        className={clsx(
          s["employee-list__item"],
          EMPLOYEE_DATA.length - 1 === index && s["employee-list__item_last"],
        )}
        employee={employee}
        isMobile={isPhone}
      />
    ),
    [isPhone],
  );

  return (
    <div className={clsx(s["employee-list"], className)}>
      <Card className={s["employee-list__card"]}>
        <CardManager employee={EMPLOYEE_MANAGER_DATA} isMobile={isPhone} />
      </Card>

      <Card className={s["employee-list__card"]}>
        <Virtuoso
          useWindowScroll
          customScrollParent={containerRef.current ?? undefined}
          data={employees}
          endReached={loadMore}
          increaseViewportBy={200}
          itemContent={itemContent}
          style={{ flex: "1", height: "100%" }}
          totalCount={EMPLOYEE_DATA.length}
        />
      </Card>
    </div>
  );
};

export default memo(EmployeeList);
