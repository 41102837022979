import {
  type FormFieldMask,
  isFormModelMask,
} from "@repo/data/store/models/FormModel";
import { InputWithMask, type InputWithMaskProps } from "@repo/ui/Input";
import WithError from "@repo/ui/WithError";

import { withField } from "../../hoc";

export type FieldMaskProps = {
  /** Имя поля формы. */
  name: string;
  /** Модель поля формы. */
  field: FormFieldMask;
} & InputWithMaskProps;

const BaseFieldMask: React.FC<FieldMaskProps> = ({
  className,
  field,
  ...props
}: FieldMaskProps) => {
  return (
    <WithError className={className} message={field.errorMessage}>
      <InputWithMask
        {...props}
        disabled={field.disable}
        error={field.hasError}
        name={field.name}
        options={field.mask}
        value={field.value}
        onBlur={field.setTouched}
        onChange={field.setValue}
      />
    </WithError>
  );
};

export const FieldMask = withField(BaseFieldMask, isFormModelMask);
