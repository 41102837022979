import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useLocalStore } from "@repo/data/hooks";
import {
  FilterOrgStructureStore,
  FilterOrgStructureStoreProvider,
} from "@repo/data/store/local/FilterStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import LayoutPage from "@app/pages/auth/LayoutPage";
import EmployeeList from "@app/pages/employees/EmployeeList";
import HeaderEmployee from "@app/pages/employees/HeaderEmployee";
import ModalOrgStructure from "@app/pages/employees/ModalOrgStructure";
import OrgStructureList from "@app/pages/employees/OrgStructureList";

import * as s from "./employees.module.scss";

export const Route = createFileRoute("/_auth/employees/$orgId/")({
  component: observer(AuthEmployeesPage),
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

function AuthEmployeesPage(): JSX.Element {
  const { orgId } = Route.useParams();

  const filterStore = useLocalStore(() => new FilterOrgStructureStore());

  useEffect(() => {
    const selectedFilter = filterStore.getFilterByKey(orgId);

    if (!selectedFilter) {
      filterStore.setDefaultFilter();
      return;
    }

    filterStore.setSelectedFilter(selectedFilter);
  }, [filterStore, orgId]);

  return (
    <FilterOrgStructureStoreProvider store={filterStore}>
      <LayoutPage className={s["employees"]}>
        <HeaderEmployee />
        <EmployeeList />
        <OrgStructureList />

        <ModalOrgStructure />
      </LayoutPage>
    </FilterOrgStructureStoreProvider>
  );
}
