import { createFileRoute, redirect } from "@tanstack/react-router";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { z } from "zod";

import { useLocalStore } from "@repo/data/hooks";
import {
  AuthFormStore,
  AuthFormStoreProvider,
} from "@repo/data/store/local/AuthFormStore";
import GlobalSettingsStore from "@repo/data/store/single/GlobalSettingsStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";
import Logo from "@repo/ui/Logo";
import { QRCodeSection } from "@repo/ui/QRCode";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";
import AppDownload from "@app/pages/login/AppDownload";
import FormLogin from "@app/pages/login/FormLogin";
import Layout from "@app/pages/login/Layout";

import placeholder from "../../../assets/og.png";

import * as s from "./login.module.scss";

export const Route = createFileRoute("/login/")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.isAuth) {
      throw redirect({ to: search.redirect || "/" });
    }
  },
  component: observer(LoginPage),
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});

function LoginPage(): JSX.Element {
  const { isDesktop } = useWidth();

  const settingsStore = GlobalSettingsStore.getInstance();

  const authFormStore = useLocalStore(() => new AuthFormStore());

  const appDownloadLink = "https://hellohr.ru";

  return (
    <Layout className={s["login-page"]}>
      <div
        className={clsx(s["login-page__column"], s["login-page__column_image"])}
      >
        <img
          alt="Изображение на странице авторизации"
          className={s["login-page__image"]}
          src={settingsStore.data.welcomeImage ?? placeholder}
        />
      </div>

      <div
        className={clsx(
          s["login-page__column"],
          s["login-page__column_content"],
        )}
      >
        <div className={s["login-page__content"]}>
          <Logo
            className={s["login-page__logo"]}
            subtitle={settingsStore.data.companyName}
            title={settingsStore.data.systemName}
            withLinkMain={false}
          />

          <Typography
            Component="h1"
            typographyType={TypographyType.H1}
            typographyWeight={TypographyWeight._700}
          >
            Добро пожаловать
          </Typography>

          <AuthFormStoreProvider store={authFormStore}>
            <FormLogin className={s["login-page__form"]} />
          </AuthFormStoreProvider>

          {isDesktop ? (
            <QRCodeSection
              className={s["login-page__code"]}
              code={appDownloadLink}
              description={
                "Отсканируйте QR-код, перейдите по ссылке и подтвердите вход в приложении"
              }
              title={"Быстрый вход через приложение"}
            />
          ) : (
            <AppDownload url={appDownloadLink} />
          )}
        </div>
      </div>
    </Layout>
  );
}
