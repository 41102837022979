import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useLocalStore } from "@repo/data/hooks";
import { ModalOrgStructureStore } from "@repo/data/store/local/ModalOrgStructureStore";
import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import Button, { ButtonSize, ButtonTheme } from "@repo/ui/Button";
import { InputSearch, InputSearchSize, InputSearchTheme } from "@repo/ui/Input";
import Loader, { LoaderSize } from "@repo/ui/Loader";
import ModalSidebar from "@repo/ui/ModalSidebar";
import Tree from "@repo/ui/Tree";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";

import * as s from "./ModalOrgStructure.module.scss";

const ModalOrgStructure: React.FC = () => {
  const { isPhone } = useWidth();

  const modalStore = useLocalStore(() => new ModalOrgStructureStore());
  const modalState = useModalsStoreOpenState(ModalsBaseKeys.ORG_STRUCTURE);

  const handleTreeNodeSelect = useCallback(() => {}, []);

  const controlButtons = (
    <div className={s["modal-structure__controls"]}>
      <Button.Base
        className={s["modal-structure__button"]}
        size={ButtonSize.MEDIUM}
        theme={ButtonTheme.DEFAULT}
      >
        Применить
      </Button.Base>

      <Button.Base
        className={s["modal-structure__button"]}
        size={ButtonSize.MEDIUM}
        theme={ButtonTheme.BORDERED}
      >
        Сбросить
      </Button.Base>
    </div>
  );

  return (
    <ModalSidebar
      controlsSlot={controlButtons}
      isMobile={isPhone}
      isOpened={modalState.opened}
      title="Оргструктура"
      onClose={modalState.close}
    >
      <InputSearch
        placeholder="Поиск по подразделению"
        size={InputSearchSize.SMALL}
        theme={InputSearchTheme.SECONDARY}
        value={modalStore.search}
        withSearchButton={false}
        onChange={modalStore.setSearch}
      />

      {modalStore.isLoading ? (
        <Loader isAbsolute size={LoaderSize.M} />
      ) : (
        <Tree
          data={modalStore.orgStructureTree}
          isMobile={isPhone}
          onSelect={handleTreeNodeSelect}
        />
      )}

      {!modalStore.hasSearchResult && (
        <div className={s["modal-structure__stub"]}>
          <Typography
            Component="p"
            typographyType={TypographyType.BODY_MD}
            typographyWeight={TypographyWeight._700}
          >
            Подразделение не найдено.
          </Typography>

          <Typography
            Component="p"
            typographyType={TypographyType.BODY_SM}
            typographyWeight={TypographyWeight._400}
          >
            Попробуйте ввести другое название.
          </Typography>
        </div>
      )}
    </ModalSidebar>
  );
};

export default observer(ModalOrgStructure);
