import clsx from "clsx";
import { memo } from "react";

import type { LabelType } from "@repo/types/label";
import Tag, { TagAngle, TagColor, TagSize } from "@repo/ui/Tag";

import * as s from "./LabelList.module.scss";

export type LabelListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Список лейблов. */
  labels: LabelType[];
  /** Угол без скругления. */
  angle?: TagAngle;
  /** Размер лейбла. */
  size?: TagSize;
};

const LabelList: React.FC<LabelListProps> = ({
  className,
  labels,
  angle = TagAngle.BOTTOM_LEFT,
  size = TagSize.M,
}: LabelListProps) => {
  return (
    <div className={clsx(s["labels"], className)}>
      {labels.map((label) => (
        <Tag
          key={label.id}
          angle={angle}
          color={TagColor.DEFAULT}
          label={label.title}
          size={size}
          style={{ backgroundColor: label.color }}
        />
      ))}
    </div>
  );
};

export default memo(LabelList);
