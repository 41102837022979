import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { memo, type PropsWithChildren, useCallback, useId } from "react";

import { IconType } from "@repo/types/icon";
import type { OptionWithKey } from "@repo/types/option";
import Icon, { IconSize } from "@repo/ui/Icon";

import * as s from "./SelectOption.module.scss";

export type SelectOptionProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Опция селекта. */
  option: OptionWithKey;
  /** Состояние checked. */
  isChecked: boolean;
  /** Callback, вызывается при изменении состояния checked. */
  onChange: (option: OptionWithKey) => void;
};

const SelectOption: React.FC<PropsWithChildren<SelectOptionProps>> = ({
  className,
  option,
  isChecked,
  onChange,
  children,
}: PropsWithChildren<SelectOptionProps>) => {
  const id = useId();

  const handleChange = useCallback(() => {
    onChange(option);
  }, [option, onChange]);

  return (
    <div className={clsx(s["option"], className)}>
      <input
        checked={isChecked}
        className={s["option__field"]}
        id={id}
        type="checkbox"
        onChange={handleChange}
      />

      <label className={s["option__label"]} htmlFor={id}>
        {children}
      </label>

      <AnimatePresence>
        {isChecked && (
          <motion.span
            animate={{ opacity: 1 }}
            className={s["option__icon"]}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Icon size={IconSize.L} type={IconType.ROUND_CHECK} />
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(SelectOption);
