import clsx from "clsx";
import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import QRCodeBlock from "../QRCodeBlock";

import * as s from "./QRCodeSection.module.scss";

export type QRCodeSectionProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Значение, которое будет закодировано в QR-коде. */
  code: string;
  /** Заголовок секции. */
  title: string;
  /** Описание секции. */
  description: string;
};

const QRCodeSection: React.FC<QRCodeSectionProps> = ({
  className,
  code,
  title,
  description,
}: QRCodeSectionProps) => {
  return (
    <div className={clsx(s["code"], className)}>
      <QRCodeBlock className={s["code__block"]} value={code} />

      <div className={s["code__content"]}>
        <Typography
          Component="h3"
          typographyType={TypographyType.BODY_LG}
          typographyWeight={TypographyWeight._700}
        >
          {title}
        </Typography>
        <Typography
          Component="p"
          className={s["code__description"]}
          typographyType={TypographyType.BODY_SM}
          typographyWeight={TypographyWeight._400}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default memo(QRCodeSection);
