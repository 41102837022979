// extracted by mini-css-extract-plugin
var _1 = "card-profile__Smx4G";
var _2 = "card-profile__content__MnksH";
var _3 = "card-profile__content_fired__BFwQ_";
var _4 = "card-profile__details__j4B82";
var _5 = "card-profile__footer__ZB5RW";
var _6 = "card-profile__header__cZivC";
var _7 = "card-profile__labels__opeKL";
var _8 = "card-profile__link__ZcUQM";
var _9 = "card-profile__name__kVHCR";
var _a = "card-profile__position__ehGaR";
var _b = "card-profile__tag__niBj8";
var _c = "card-profile__title__MMQlW";
var _d = "card-profile_with-border__duqL9";
export { _1 as "card-profile", _2 as "card-profile__content", _3 as "card-profile__content_fired", _4 as "card-profile__details", _5 as "card-profile__footer", _6 as "card-profile__header", _7 as "card-profile__labels", _8 as "card-profile__link", _9 as "card-profile__name", _a as "card-profile__position", _b as "card-profile__tag", _c as "card-profile__title", _d as "card-profile_with-border" }
