import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useAvatarChangeStore } from "@repo/data/store/local/AvatarChangeStore";
import Button, { ButtonTheme } from "@repo/ui/Button";
import ImageCropper from "@repo/ui/ImageCropper";

import { ProfileEditTabStage } from "../../config";

import * as s from "./ProfileEditAvatar.module.scss";

export type ProfileEditAvatarProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Функция, вызываемая при изменении шага редактирования. */
  onStageChange: (stage: ProfileEditTabStage) => void;
};

const ProfileEditAvatar: React.FC<ProfileEditAvatarProps> = ({
  className,
  onStageChange,
}: ProfileEditAvatarProps) => {
  const avatarStore = useAvatarChangeStore();

  const handleSaveClick = useCallback(async () => {
    await avatarStore.changeImage();

    onStageChange(ProfileEditTabStage.FORM);
  }, [avatarStore, onStageChange]);

  const handleBackClick = useCallback(() => {
    onStageChange(ProfileEditTabStage.FORM);
  }, [onStageChange]);

  return (
    <div className={clsx(s["avatar-stage"], className)}>
      {avatarStore.image && (
        <ImageCropper
          imageSrc={avatarStore.image}
          isLoading={avatarStore.meta.isLoading}
          objectFit={"horizontal-cover"}
          onCropComplete={avatarStore.setCroppedImage}
        />
      )}

      <div className={s["avatar-stage__buttons"]}>
        <Button.Base
          className={s["avatar-stage__button"]}
          disabled={avatarStore.meta.isLoading}
          theme={ButtonTheme.BORDERED}
          onClick={handleBackClick}
        >
          Вернуться назад
        </Button.Base>

        <Button.Base
          className={s["avatar-stage__button"]}
          disabled={avatarStore.meta.isLoading}
          onClick={handleSaveClick}
        >
          Сохранить
        </Button.Base>
      </div>
    </div>
  );
};

export default observer(ProfileEditAvatar);
