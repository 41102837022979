import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { useLocalStore } from "@repo/data/hooks";
import { CollapseStore } from "@repo/data/store/local/CollapseStore";
import { IconType } from "@repo/types/icon";
import { CardEmployee, CardTab } from "@repo/ui/Card";

import useWidth from "@app/hooks/useWidth";

import {
  CHILD_DATA,
  COIN_DATA,
  EDO_DATA,
  EMPLOYEE_DATA,
  ORGANIZATION_DATA,
} from "./mocks/__mocks__";

import * as s from "./CollapseTabs.module.scss";

export type CollapseTabsProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const CollapseTabs: React.FC<CollapseTabsProps> = ({
  className,
}: CollapseTabsProps) => {
  const { isPhone } = useWidth();

  const collapseStore = useLocalStore(() => new CollapseStore());

  return (
    <div className={clsx(s["root"], className)}>
      <CardTab
        card={{
          id: "Руководитель и HR",
          title: "Руководитель и HR",
          iconType: IconType.PEOPLE_3D,
        }}
        defaultOpened={
          isPhone ? collapseStore.isTabOpened("Руководитель и HR") : true
        }
        isCollapsable={isPhone}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        {({ isOpened, isCollapsable }) => (
          <CardTab.Content isCollapsable={isCollapsable} isOpened={isOpened}>
            {EMPLOYEE_DATA.map((item) => (
              <CardEmployee
                key={item.employeeNumber}
                employee={item}
                isMobile={isPhone}
              />
            ))}
          </CardTab.Content>
        )}
      </CardTab>

      <CardTab
        isCollapsable
        card={{
          id: "Организационные данные",
          title: "Организационные данные",
          iconType: IconType.INFO_3D,
        }}
        defaultOpened={collapseStore.isTabOpened("Организационные данные")}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        {({ isOpened, isCollapsable }) => {
          return (
            <>
              <CardTab.Content>
                {ORGANIZATION_DATA.slice(0, 2).map((item) => (
                  <CardTab.Detail
                    key={item.id}
                    text={item.text}
                    title={item.title}
                  />
                ))}
              </CardTab.Content>

              <CardTab.Content
                isCollapsable={isCollapsable}
                isOpened={isOpened}
              >
                {ORGANIZATION_DATA.slice(2).map((item) => (
                  <CardTab.Detail
                    key={item.id}
                    text={item.text}
                    title={item.title}
                  />
                ))}
              </CardTab.Content>
            </>
          );
        }}
      </CardTab>

      <CardTab
        card={{
          id: "Электронный документооборот",
          title: "Электронный документооборот",
          subtitle: "Подключен",
          iconType: IconType.DOCUMENT_3D,
        }}
        defaultOpened={collapseStore.isTabOpened("Электронный документооборот")}
        isCollapsable={false}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        <CardTab.Content>
          {EDO_DATA.slice(0, 2).map((item) => (
            <CardTab.Detail key={item.id} text={item.text} title={item.title} />
          ))}
        </CardTab.Content>
      </CardTab>

      <CardTab
        card={{
          id: "Зарплата",
          title: "Зарплата",
          subtitle: "120 000 ₽",
          iconType: IconType.COINS_3D,
        }}
        defaultOpened={collapseStore.isTabOpened("Зарплата")}
        isCollapsable={false}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        <CardTab.Content>
          {COIN_DATA.slice(0, 2).map((item) => (
            <CardTab.Detail key={item.id} text={item.text} title={item.title} />
          ))}
        </CardTab.Content>
      </CardTab>

      <CardTab
        card={{
          id: "Дети",
          title: "Дети",
          iconType: IconType.TOY_3D,
        }}
        defaultOpened={collapseStore.isTabOpened("Дети")}
        isCollapsable={false}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        <CardTab.Content>
          {CHILD_DATA.slice(0, 2).map((item) => (
            <CardTab.Detail key={item.id} text={item.text} title={item.title} />
          ))}
        </CardTab.Content>
      </CardTab>
    </div>
  );
};

export default observer(CollapseTabs);
