import { memo } from "react";

import IconBase, { type IconBaseProps } from "./IconBase";

const RoundCheck: React.FC<IconBaseProps> = (props: IconBaseProps) => {
  return (
    <IconBase {...props} height="32" viewBox="0 0 32 32" width="32">
      <path
        d="M16 3c-2.5712 0-5.0846.76244-7.22241 2.1909-2.13784 1.42845-3.80408 3.45878-4.78802 5.8342-.98394 2.3755-1.24138 4.9893-.73977 7.5111.50161 2.5217 1.73973 4.8381 3.55782 6.6562 1.81808 1.8181 4.13448 3.0562 6.65618 3.5578 2.5218.5016 5.1357.2442 7.5111-.7398 2.3754-.9839 4.4058-2.6501 5.8342-4.788C28.2376 21.0846 29 18.5712 29 16c-.0036-3.4467-1.3744-6.75118-3.8116-9.18837C22.7512 4.37445 19.4467 3.00364 16 3Zm5.7075 10.7075-7 7c-.0929.093-.2032.1667-.3246.2171-.1214.0503-.2515.0762-.3829.0762-.1314 0-.2615-.0259-.3829-.0762-.1214-.0504-.2317-.1241-.3246-.2171l-3-3c-.1876-.1876-.29305-.4421-.29305-.7075s.10545-.5199.29305-.7075.4421-.2931.7075-.2931.5199.1055.7075.2931L14 18.5863l6.2925-6.2938c.0929-.0929.2032-.1666.3246-.2169.1214-.0503.2515-.0762.3829-.0762.1314 0 .2615.0259.3829.0762.1214.0503.2317.124.3246.2169.0929.0929.1666.2032.2169.3246.0503.1214.0762.2515.0762.3829 0 .1314-.0259.2615-.0762.3829-.0503.1214-.124.2317-.2169.3246Z"
        fill="currentColor"
      />
    </IconBase>
  );
};

export default memo(RoundCheck);
