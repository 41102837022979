import clsx from "clsx";
import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { CounterSize } from "./config";

import * as s from "./Counter.module.scss";

export type CounterProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Основное значение. */
  count: string;
  /** Дополнительное значение. */
  allCount?: string;
  /** Размер счетчика. */
  size: CounterSize;
  /** Наличие рамки у счетчика. */
  withBorder?: boolean;
  /** Тип шрифта. */
  typographyType?: TypographyType;
  /** Толщина шрифта. */
  typographyWeight?: TypographyWeight;
};

const Counter: React.FC<CounterProps> = ({
  className,
  count,
  allCount,
  size,
  withBorder,
  typographyType = TypographyType.CAPTION_EXTRA_SM,
  typographyWeight = TypographyWeight._500,
}: CounterProps) => {
  const classNames = clsx(
    s["counter"],
    s[`counter_size-${size}`],
    withBorder && s["counter_with-border"],
    className,
  );

  return (
    <div className={classNames}>
      <Typography
        Component="p"
        className={s["counter__text"]}
        typographyType={typographyType}
        typographyWeight={typographyWeight}
      >{`${count}${allCount ? `/${allCount}` : ""}`}</Typography>
    </div>
  );
};

export default memo(Counter);
