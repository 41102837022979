import clsx from "clsx";
import { memo } from "react";

import Button, { type ButtonBaseProps } from "@repo/ui/Button";

import { useFormContext } from "../../context";

import * as s from "./FormSubmit.module.scss";

export type FormSubmitProps = ButtonBaseProps;

const FormSubmit: React.FC<FormSubmitProps> = ({
  className,
  children,
  ...props
}: FormSubmitProps) => {
  const form = useFormContext();

  return (
    <Button.Base
      {...props}
      className={clsx(s["form-submit"], className)}
      disabled={form.meta.isLoading}
      type="submit"
    >
      {children}
    </Button.Base>
  );
};

export default memo(FormSubmit);
