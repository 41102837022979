import { z } from "zod";

import AFormField from "../AFormField";
import type { FieldModelOptions } from "../AFormField";

class FormFieldNumber extends AFormField<number, string | number> {
  constructor(options: FieldModelOptions<number>) {
    super({ ...options });
  }

  protected _prepareValue(value: string | number): number {
    if (typeof value === "number") {
      return value;
    }

    const schema = z.string().transform((value) => {
      const parsed = parseFloat(value);

      if (isNaN(parsed)) {
        throw new Error("Invalid number");
      }

      return parsed;
    });

    return schema.parse(value);
  }

  destroy(): void {
    super.destroy();
  }
}

export default FormFieldNumber;
