import { RuleKey } from "@repo/data/config";

import GlobalInterfaceRuleStore from "./GlobalInterfaceRuleStore";

export const useInterfaceRuleStore = () =>
  GlobalInterfaceRuleStore.getInstance();

export const useInterfaceRule = (key: RuleKey) => {
  const interfaceRuleStore = useInterfaceRuleStore();

  return interfaceRuleStore.getInterfaceRuleByKey(key);
};
